import React, { useState, useEffect } from 'react';
import { TextField, Grid, Select, MenuItem, FormControl, InputLabel, FormHelperText } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { baseUrl } from '../../utils/baseUrl';
import dayjs from 'dayjs';

const PrimaryHolderInformation = ({ formData, handleChange, errors }) => {
  const { firstName, middleName, lastName, dob, panExempt, pan, exemptCategory, mobileNumber, email, mobileRelation, emailRelation, gender } = formData.primaryHolder;
  const [mobileRelationOptions, setMobileRelationOptions] = useState([]);
  const [validDate, setValidDate] = useState();

  useEffect(() => {
    const fetchMobileRelationOptions = async () => {
      try {
        const response = await fetch(`${baseUrl}/config/emailmobilerelations`); // Replace with your API endpoint
        const data = await response.json();
        setMobileRelationOptions(data);
      } catch (error) {
        console.error('Error fetching mobile relation options:', error);
      }
    };

    fetchMobileRelationOptions();
  }, []);

  const handleDateChange = (date) => {
    const convertedTz = convertTZ(date, "Asia/Kolkata");
    const requiredDate = toShortFormat(convertedTz);
    handleChange({
      target: {
        name: 'primaryHolder.dob',
        value: requiredDate,
      },
    });
  };

  function convertTZ(date, tzString) {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString }));
  }

  function toShortFormat(date) {
    const monthNames = ["Jan", "Feb", "Mar", "Apr",
      "May", "Jun", "Jul", "Aug",
      "Sep", "Oct", "Nov", "Dec"];

    const day = date.getDate();
    if(day<10){
      setValidDate("0"+day);
      //day = "0"+day;
    } else{
      setValidDate(day);
    }
    
    const monthIndex = date.getMonth();
    const monthName = monthNames[monthIndex];

    const year = date.getFullYear();

    return `${validDate}-${monthName}-${year}`;
  }

  return (
    <section style={{ width: '85%', margin: '40px auto' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="First Name"
            name="primaryHolder.firstName"
            value={firstName}
            onChange={handleChange}
            required
            error={!!errors.firstName}
            helperText={errors.firstName}
            InputProps={{
              sx: { borderRadius: '15px' },
            }}

          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="Middle Name"
            name="primaryHolder.middleName"
            value={middleName}
            onChange={handleChange}
            InputProps={{
              sx: { borderRadius: '15px' },
            }}
        

          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="Last Name"
            name="primaryHolder.lastName"
            value={lastName}
            onChange={handleChange}
            required
            error={!!errors.lastName}
            helperText={errors.lastName}
            InputProps={{
              sx: { borderRadius: '15px' },
            }}

          />
        </Grid>
        <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                        <InputLabel id="bank-account-type">Gender</InputLabel>
                        <Select
                            labelId="gender-type"
                            id="gender-type"
                            name="primaryHolder.gender"
                            value={gender}
                            onChange={handleChange}
                            label="Gender"
                            sx={{ borderRadius: '15px' }}

                        >
                            <MenuItem value={"M"}>Male – M </MenuItem>
                            <MenuItem value={"F"}>Female - F </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
        <Grid item xs={12} md={4}>
          <LocalizationProvider dateAdapter={AdapterDayjs} sx={{width:"100%"}}>
            <DatePicker
              label="Date of Birth"
              value={dayjs(dob)}
              onChange={handleDateChange}
              sx={{width:"100%"}}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  error={!!errors.dob}
                  helperText={errors.dob}
                  InputProps={{
                    sx: { borderRadius: '15px' },
                  }}

                />
              )}
              required
            />
          </LocalizationProvider>
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="PAN Exempt"
            name="primaryHolder.panExempt"
            value={panExempt}
            onChange={handleChange}
          />
        </Grid> */}
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="PAN"
            name="primaryHolder.pan"
            value={pan}
            onChange={handleChange}
            required
            error={!!errors.pan}
            helperText={errors.pan}
            InputProps={{
              sx: { borderRadius: '15px' },
            }}

          />
        </Grid>
        {/* <Grid item xs={12}>
          <TextField
            fullWidth
            label="Exempt Category"
            name="primaryHolder.exemptCategory"
            value={exemptCategory}
            onChange={handleChange}
          />
        </Grid> */}
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="Mobile Number"
            name="primaryHolder.mobileNumber"
            value={mobileNumber}
            onChange={handleChange}
            required
            error={!!errors.mobileNumber}
            helperText={errors.mobileNumber}
            InputProps={{
              sx: { borderRadius: '15px' },
            }}

          />
        </Grid>
        {/* <Grid item xs={12} md={4}>
          <FormControl fullWidth error={!!errors.mobileRelation}>
            <InputLabel id="mobile-relation-label">Mobile Relation *</InputLabel>
            <Select
              labelId="mobile-relation-label"
              id="mobile-relation"
              name="primaryHolder.mobileRelation"
              value={mobileRelation}
              onChange={handleChange}
              label="Mobile Relation"
              required
              sx={{ borderRadius: '15px' }}


            >
              {mobileRelationOptions && mobileRelationOptions.map((option, index) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
            {errors.mobileRelation && <FormHelperText>{errors.mobileRelation}</FormHelperText>}
          </FormControl>
        </Grid> */}
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="Email"
            name="primaryHolder.email"
            value={email}
            onChange={handleChange}
            required
            error={!!errors.email}
            helperText={errors.email}
            InputProps={{
              sx: { borderRadius: '15px' },
            }}

          />
        </Grid>
        {/* <Grid item xs={12} md={4}>
          <FormControl fullWidth error={!!errors.emailRelation}>
            <InputLabel id="mobile-relation-label">Email Relation *</InputLabel>
            <Select
              labelId="email-relation-label"
              id="email-relation"
              name="primaryHolder.emailRelation"
              value={emailRelation}
              onChange={handleChange}
              label="Email Relation"
              required
              sx={{ borderRadius: '15px' }}

            >
              {mobileRelationOptions && mobileRelationOptions.map((option, index) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
            {errors.emailRelation && <FormHelperText>{errors.emailRelation}</FormHelperText>}
          </FormControl>
        </Grid> */}
      </Grid>
    </section>
  );
};

export default PrimaryHolderInformation;
