import React, { useEffect, useState } from 'react';
import { TextField, Grid, Select, MenuItem, FormControl, InputLabel, FormHelperText } from '@mui/material';
import { baseUrl } from '../../utils/baseUrl';

const BankDetailsInformation = ({ formData, handleChange, errors }) => {
  const { accountType, accountNumber, ifscCode, defaultBank, micrNumber, bankName, branchName, branchAddress1 } = formData.accountInfo1;
  const [accountTypeList, setAccountTypeList] = useState([]);
  const [bankNameList, setBankNameList] = useState([]);

  useEffect(() => {
    const fetchAccountTypeListOptions = async () => {
      try {
        const response = await fetch(`${baseUrl}/config/accountTypes`);
        const data = await response.json();
        if (response.ok) {
          setAccountTypeList(data);
        }
      } catch (error) {
        console.error('Error fetching account type options:', error);
      }
    };

    const fetchBankNameListOptions = async () => {
      try {
        const response = await fetch(`${baseUrl}/config/banknames`);
        const data = await response.json();
        if (response.ok) {
          setBankNameList(data);
        }
      } catch (error) {
        console.error('Error fetching bank name options:', error);
      }
    };

    fetchAccountTypeListOptions();
    fetchBankNameListOptions();
  }, []);

  return (
    <section style={{ width: '85%', margin: '40px auto' }}>

    <Grid container spacing={2}>

    <Grid item xs={6}>
        <FormControl fullWidth error={!!errors.bankName}>
          <InputLabel id="bank-name">Bank Name</InputLabel>
          <Select
            labelId="bank-name"
            id="bank-name"
            name="accountInfo1.bankName"
            value={bankName}
            onChange={handleChange}
            label="Bank Name"
            sx={{ borderRadius: '15px' }}
          >
            {bankNameList.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
          {errors.bankName && <FormHelperText>{errors.bankName}</FormHelperText>}
        </FormControl>
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="Account Number"
          name="accountInfo1.accountNumber"
          required
          value={accountNumber}
          onChange={handleChange}
          error={!!errors.accountNumber}
          helperText={errors.accountNumber}
          InputProps={{
            sx: { borderRadius: '15px' },
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth error={!!errors.accountType}>
          <InputLabel id="bank-account-type">Account Type</InputLabel>
          <Select
            labelId="bank-account-type"
            id="bank-account-type"
            name="accountInfo1.accountType"
            value={accountType}
            onChange={handleChange}
            label="Account Type"
            sx={{ borderRadius: '15px' }}
          >
            {accountTypeList.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
          {errors.accountType && <FormHelperText>{errors.accountType}</FormHelperText>}
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="IFSC Code"
          name="accountInfo1.ifscCode"
          required
          value={ifscCode}
          onChange={handleChange}
          error={!!errors.ifscCode}
          helperText={errors.ifscCode}
          InputProps={{
            sx: { borderRadius: '15px' },
          }}
        />
      </Grid>
      {/* <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="MICR Number"
          name="accountInfo1.micrNumber"
          value={micrNumber}
          onChange={handleChange}
          InputProps={{
            sx: { borderRadius: '15px' },
          }}
        />
      </Grid> */}
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Branch Name"
          name="accountInfo1.branchName"
          value={branchName}
          onChange={handleChange}
          error={!!errors.branchName}
          helperText={errors.branchName}
          InputProps={{
            sx: { borderRadius: '15px' },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Branch Address"
          name="accountInfo1.branchAddress1"
          value={branchAddress1}
          onChange={handleChange}
          error={!!errors.branchAddress1}
          helperText={errors.branchAddress1}
          InputProps={{
            sx: { borderRadius: '15px' },
          }}
        />
      </Grid>
    </Grid>
    </section>
  );
};

export default BankDetailsInformation;
