// store.js
import { configureStore, createSlice } from '@reduxjs/toolkit';

const initialState = {
  uniqueClientCode: "",
  mfOrderId: null,
  mutualFundCardItemDetailDTOList: [
    {
      mfProductId: "",
      orderType: "",
      amount: "",
      sipInfo: {
        numberOfSips: 24,
        frequency: "Monthly",
        sipStartDay: "26",
        isPerpetual: false,
        amount: "20000",
        channelType: "NET",
        isGoalSip: false
      }
    }
  ],
  paymentMode: "ONLINE"
};

const mutualFundSlice = createSlice({
  name: 'mutualFund',
  initialState,
  reducers: {
    updateMfOrder: (state, action) => {
      const { mfProductId, orderType, amount, sipInfo } = action.payload;
      state.mfOrderId = action.payload.mfOrderId || state.mfOrderId;
      state.mutualFundCardItemDetailDTOList[0] = {
        ...state.mutualFundCardItemDetailDTOList[0],
        mfProductId,
        orderType,
        amount,
        sipInfo: {
          ...state.mutualFundCardItemDetailDTOList[0].sipInfo,
          ...sipInfo
        }
      };
    }
  }
});

export const { updateMfOrder } = mutualFundSlice.actions;

const store = configureStore({
  reducer: {
    mutualFund: mutualFundSlice.reducer
  }
});

export default store;
