import React, { useState } from 'react';
import './myholding.css';
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { data } from '../../utils/data'
import { getPLColor } from '../../utils/function';
import ListIcon from '@mui/icons-material/List';
import LockResetIcon from '@mui/icons-material/LockReset';
import DataUsageIcon from '@mui/icons-material/DataUsage';
const MyHoldings = () => {
    const [filter, setFilter] = useState('all');
    const filteredFunds = data.funds.filter(fund => {
        if (filter === 'all') return true;
        return fund.category.includes(filter);
    });

    return (
        <div className="investment-dashboard">
            <div className="overview">
                <h2>Overview</h2>
                <Box sx={{ display: "flex", justifyContent: "space-around", margin: "20px 0" }}>
                    <div className='overview-menu'>
                        <span>Invested</span>
                        <span>{data.overview.invested}</span>
                    </div>
                    <div className='overview-menu'>
                        <span>Current</span>
                        <span>{data.overview.current}</span>
                    </div>
                    <div className='overview-menu'>
                        <span>P&L</span>
                        <span>{data.overview.pl} ({data.overview.plPercent})</span>
                    </div>
                    <div className='overview-menu'>
                        <span>XIRR</span>
                        <span>{data.overview.xirr}</span>
                    </div>
                </Box>
                <Box sx={{
                    justifyContent: "space-around",
                    display: "flex",
                    padding: "10px",
                    backgroundColor: "#ddd",
                }}>
                    <Button>
                        <ListIcon />Breakdown
                    </Button>
                    <Button>
                        <DataUsageIcon />
                        Analytics
                    </Button>
                    <Button>ELSS statement</Button>
                    <Button>
                        <LockResetIcon />CDSL Authorization
                    </Button>
                </Box>
            </div>

            <div className="funds">
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '16px' }}>
                    <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
                        <InputLabel>Filter</InputLabel>
                        <Select
                            value={filter}
                            onChange={(e) => setFilter(e.target.value)}
                            label="Filter"
                        >
                            <MenuItem value="all">All</MenuItem>
                            <MenuItem value="Growth · Equity · Mid Cap">Growth · Equity · Mid Cap</MenuItem>
                            <MenuItem value="Growth · Hybrid · Aggressive">Growth · Hybrid · Aggressive</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Funds</TableCell>
                                <TableCell>Invested</TableCell>
                                <TableCell>Current</TableCell>
                                <TableCell>P&L</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredFunds.map((fund, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                            <img src={fund.logo} alt={fund.name} width={25} height={25} />
                                            <div>
                                                <p className="fund-name">{fund.name}</p>
                                                <p className="fund-category">{fund.category}</p>
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell>₹{fund.invested.toLocaleString()}</TableCell>
                                    <TableCell>₹{fund.current.toLocaleString()}</TableCell>
                                    <TableCell style={{ color: getPLColor(fund.plPercent) }}>
                                        ₹{fund.pl.toLocaleString()}<br />
                                        <span style={{ fontSize: ".75rem" }}>({fund.plPercent})</span>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
};

export default MyHoldings;
