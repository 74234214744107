import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { baseUrl } from '../utils/baseUrl';
import { Paper, Box, Typography, Button, Grid ,CircularProgress} from '@mui/material';
import { useSidebar } from '../Context/SidebarContext';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { useUser } from '../Context/UserContext';
//import { getWatchList, addToWatchList, removeFromWatchList } from '../utils/watchList';

import './pages.style.css'

const Explore = () => {
    const navigate = useNavigate()
    const { selectedFilters, removeFilter } = useSidebar();
    const [mutualFund, setMutualFund] = useState([]);
    const [watchList, setWatchList] = useState([]);
    const [numberOfResult, setNumberOfResult] = useState([]);
    const [numberOFPages, setNumberOFPages] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [mFwatchListElementDTOS, setMFwatchListElementDTOS] = useState([]);
    const { userInfo } = useUser();


    useEffect(() => {
        const getMutualFunds = async () => {
            try {
                const body = {
                    amcs: [],
                    productCategories: [],
                    productSubCategories: [],
                    dividendType: [],
                    dividendPlans: []
                };
                const uniqueClientCode=JSON.parse(window.localStorage.getItem("userInfo"))?.clientCode || "";
                const response = await fetch(`${baseUrl}/products/mutual-funds?productCategories=${selectedFilters.productCategories}&amcs=${selectedFilters.amcs}&productSubCategories=${selectedFilters.productSubCategories}&dividendType=${selectedFilters.dividendType}&dividendPlans=${selectedFilters.dividendPlans}&riskLevels=${selectedFilters.riskLevels}&returnLevels=${selectedFilters.returnLevels}&uniqueClientCode=${uniqueClientCode}&page=${currentPage}&size=15`);

                if (!response.ok) {
                    throw new Error("An error occurred");
                }

                const data = await response.json();
                console.log("Printing data", data);
                setMutualFund(data.mfProductResponseElementDTOList);
                setNumberOFPages(data.numberOFPages);
                setNumberOfResult(data.numberOfResult);
                setCurrentPage(data.currentPage);
                setNumberOfResult(data.numberOfResult);
            } catch (error) {
                console.error("Error fetching mutual funds:", error);
            }
        };

        getMutualFunds();
    }, [selectedFilters, currentPage]);
    console.log("mutualFund", mutualFund)

    const showMfInfo = (mfProductId) => {
        console.log("mfProductId", mfProductId)
        navigate(`/mf/product-info/${mfProductId}`)
    }

    

    const nextPage = () => {
        if (currentPage < numberOFPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    useEffect(() => {
        getWatchList();
    }, []);

    const getWatchList = async() => {
        console.log("Inside getWatchList function....");
        try{
            const uniqueClientCode=JSON.parse(window.localStorage.getItem("userInfo"))?.clientCode || "";
            const userWatchListResponse = await fetch(`${baseUrl}/user/watchList?uniqueClientCode=${uniqueClientCode}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const data = await userWatchListResponse.json();
            console.log("Printing data from watchlist api...."+ data.mfwatchListElementDTOS.length);
            //setWatchList(data);
            setMFwatchListElementDTOS(data.mfwatchListElementDTOS);
            console.log("Printing mfWatchlistdata..."+ mFwatchListElementDTOS);
        } catch(error){
            console.error("Printing error..."+ error);
        }
    };

    const handleAddToWatchList = async (mfProductId, mfProductName) => {
        console.log("Inside handle add to watchlist....", mfProductId);
        const uniqueClientCode=JSON.parse(window.localStorage.getItem("userInfo")).clientCode
        if (!uniqueClientCode){
            navigate("/login")
        }
        const watchListData = {
            uniqueClientCode: uniqueClientCode,
            mfProductID: mfProductId,
            status: true,
        };
        const response= await fetch(`${baseUrl}/user/add-to-watchlist`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(watchListData)
        });
        const data = await response.json();
        console.log("Printing add to watchlist response....", data);
        if(data.status === true){
            getWatchList();
        }

        //console.log("Ptinting client code" + userData.clientCode);
        // if (watchList.some(item => item.mfProductId === mfProductId)) {
        //     removeFromWatchList(mfProductId);
        // } else {
        //     addToWatchList(mfProductId, mfProductName);
        // }
        // setWatchList(getWatchList());
    };

    const handleInvetNow=async()=>{
        alert("invest now")
    }

    // const isInWatchList = watchList.some(item => item.mfProductId === mfProductInfo.mfProductId);

    return (
        
        <div className='manage-content-area'>
            {/* <section>
        {loading && (
                                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                            <CircularProgress />
                                        </Box>
                                    )}
        </section> */}
            <Box>
                {Object.keys(selectedFilters).map((filterKey) => (
                    selectedFilters[filterKey].length > 0 && (
                        <Box key={filterKey} display={"flex"} alignItems="center" padding={"20px"}>
                            {selectedFilters[filterKey].map((filterValue, index) => (

                                filterValue && <Typography key={index} variant="body1" component="span" style={{
                                    marginRight: '8px',
                                    padding: "2px 10px",
                                    backgroundColor: "#1976d2",
                                    borderRadius: "30px",
                                    color: "#fff",
                                    cursor: "pointer"

                                }}>
                                    {filterValue}  <span style={{ marginLeft: "2px" }}
                                        onClick={() => removeFilter(filterKey, filterValue)}>X</span>
                                </Typography>

                            ))}
                        </Box>
                    )
                ))}
            </Box>
            <Box p={2} sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px"
            }}>
                {mutualFund.map((item, index) => (
                    <Paper key={index} sx={{ padding: "10px 5px" }}>
                        <Grid container>
                            <Grid item sx={12} md={9} onClick={() => showMfInfo(item.mfProductId)}>
                                <Box sx={{ display: "flex", gap: "20px" }}>
                                    <img src="/assets/logo/mutual_fund.png" alt="mutual-fund" width={30} height={30} />
                                    <Box sx={{ width: "100%" }}>
                                        <h5>{item.mfProductName}</h5>
                                        <section className='mutual-fund-des'>
                                            <div className='desc-section'>
                                                <span>₹{item.aumInCr} cr</span>
                                                <span>{item.mfCategory}</span>
                                                <span>{item.mfSubCategory}</span>
                                                <span>{item.dividendtype}</span>
                                                <span>{item.dividendPlan}</span>
                                            </div>
                                        </section>
                                    </Box>
                                </Box>

                            </Grid>
                            <Grid item xs={12} md={3} sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between"
                            }}>
                                <Box sx={{display:"flex",justifyContent:"center"}}>
                                    <button className='invest-now' onClick={() => handleInvetNow(item.mfProductId, item.mfProductName)}>
                                        Invest Now
                                    </button>
                                </Box>
                                <Box sx={{display:"flex",justifyContent:"center"}}>
                                    <button className='add-to-watchlist-btn' onClick={() => handleAddToWatchList(item.mfProductId, item.mfProductName)}>
                                        {mFwatchListElementDTOS && mFwatchListElementDTOS.some(product => product.mfProductId === item.mfProductId) ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                                        Add to Watchlist
                                    </button>
                                </Box>
                            </Grid>
                        </Grid>


                    </Paper>
                ))}
            </Box>

            <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
                <Button variant="contained" disabled={currentPage === 1} onClick={prevPage}>
                    Previous
                </Button>
                <Typography variant="body1">Page {currentPage} of {numberOFPages}</Typography>
                <Button variant="contained" disabled={currentPage === numberOFPages} onClick={nextPage}>
                    Next
                </Button>
            </Box>
        </div>
    );
};

export default Explore;
