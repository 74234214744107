import React, { useEffect, useState } from 'react';
import { TextField, Grid, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { baseUrl } from '../../utils/baseUrl';

const GeneralInfo = ({ formData, handleChange }) => {
    const { taxStatus, gender, occupation, holdingNature } = formData;
    const [taxStatusList, setTaxStatusList] = useState([]);
    const [occupationList, setOccupationList] = useState([]);
    const [holdingNatureList, setHoldingNatureList] = useState(["SINGLE"]);


    const fetchTaxStatusListOptions = async () => {
        try {
            const response = await fetch(`${baseUrl}/config/taxDetailsList`);
            const data = await response.json();
            setTaxStatusList(data);
        } catch (error) {
            console.error('Error fetching mobile relation options:', error);
        }
    };

    const fetchOccupationListOptions = async () => {
        try {
            const response = await fetch(`${baseUrl}/config/occupationList`);
            const data = await response.json();
            setOccupationList(data);
        } catch (error) {
            console.error('Error fetching mobile relation options:', error);
        }
    };

    // const fetchHoldingNatureListOptions = async () => {
    //     try {
    //         const response = await fetch('http://sanniddhi.com/config/banknames');
    //         const data = await response.json();
    //         setHoldingNatureList(data);
    //     } catch (error) {
    //         console.error('Error fetching mobile relation options:', error);
    //     }
    // };
    useEffect(() => {
        fetchTaxStatusListOptions()
        fetchOccupationListOptions()
        // fetchHoldingNatureListOptions()

    }, []);
    return (
        <section style={{ width: '85%', margin: '40px auto' }}>

            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <InputLabel id="taxStatus-type">TaxStatus Type</InputLabel>
                        <Select
                            labelId="taxStatus-type"
                            id="taxStatus-type"
                            name="taxStatus"
                            value={taxStatus}
                            onChange={handleChange}
                            label="TaxStatus Type"
                            sx={{ borderRadius: '15px' }}

                        >
                            {taxStatusList && taxStatusList.map((option, index) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <InputLabel id="occupation-type">Occupations</InputLabel>
                        <Select
                            labelId="occupation-type"
                            id="occupation-type"
                            name="occupation"
                            value={occupation}
                            onChange={handleChange}
                            label="Occupation"
                            sx={{ borderRadius: '15px' }}

                        >
                            {occupationList && occupationList.map((option, index) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                {/* <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <InputLabel id="holdingNature-type">Holding Nature</InputLabel>
                        <Select
                            labelId="holdingNature-type"
                            id="holdingNature-type"
                            name="holdingNature"
                            value={holdingNature}
                            onChange={handleChange}
                            label="Holding Nature"
                            sx={{ borderRadius: '15px' }}

                        >
                            {holdingNatureList && holdingNatureList.map((option, index) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid> */}
            </Grid>
        </section>
    );
};

export default GeneralInfo;
