import React from 'react';
import { Tabs, Tab } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link, useLocation } from 'react-router-dom';

const StyledTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: '#ffffff',
  color: '#000000',
  boxShadow: 'none',
  display: 'flex',
  flexGrow: 1,
}));

const DashboardTopBar = React.memo(() => {
  const location = useLocation();

  const getTabIndex = React.useCallback((pathname) => {
    switch (pathname) {
      case '/dashboard/summary':
        return 0;
      case '/distributor/make/trxn':
        return 1;
      case '/distributor/generate/reports':
        return 2;
      case '/dashboard/profile':
        return 3;
    //   case '/mutual-funds/orders':
    //     return 4;
      default:
        return false;
    }
  }, []);

  
  return (
    <StyledTabs value={getTabIndex(location.pathname)} textColor="inherit" indicatorColor="primary" variant="scrollable">
      <Tab
        label="Investment Summary"
        component={Link}
        to="/dashboard/summary"
        sx={{ textDecoration: 'none', color: 'inherit' }}
      />
      {/* <Tab
        label="Make Transaction"
        component={Link}
        to="/distributor/make/trxn"
        sx={{ textDecoration: 'none', color: 'inherit' }}
      />
      <Tab
        label="Reports"
        component={Link}
        to="/distributor/generate/reports"
        sx={{ textDecoration: 'none', color: 'inherit' }}
      /> */}
      <Tab
        label="Profile"
        component={Link}
        to="/dashboard/profile"
        sx={{ textDecoration: 'none', color: 'inherit' }}
      />
    </StyledTabs>
  );
});

export default DashboardTopBar;
