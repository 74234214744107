import React, { useState, useEffect } from 'react';
import { TextField, Grid,FormHelperText, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { baseUrl } from '../../utils/baseUrl';

const ResidentAddressInformation = ({ formData, handleChange,errors }) => {
  
  const [addressypeList, setAddressTypeList] = useState([]);

  const [countryList, setCountryList] = useState(["India"]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState(["-- Please select state --"]);
  const [pincodeList, setPincodeList] = useState(["-- Please select city --"]);

  const fetchStateList = async () => {
    try{
        const response = await fetch(`${baseUrl}/config/stateList`);
        const stateData =  await response.json();
        setStateList(stateData);
    } catch (error){
        console.error("Exception while fetching state lists.",error)
    }
  }

  const fetchAddressTypeOptions = async () => {
    try {
      const response = await fetch(`${baseUrl}/config/addressTypes`);
      const data = await response.json();
      setAddressTypeList(data);
    } catch (error) {
      console.error('Error fetching mobile relation options:', error);
    }
  };

  const populateCityList = async (event) => {
    try{
        formData.residentAddress.state = event.target.value;
        const response = await fetch(`${baseUrl}/config/cityList?state=${formData.residentAddress.state}`);
        const stateData =  await response.json();
        setCityList(stateData);
    } catch (error){
        console.error("Exception while fetching state lists.",error)
    }
  }

  const populatePinCodeList = async (event) => {
    try{
        formData.residentAddress.city = event.target.value;
        const response = await fetch(`${baseUrl}/config/pinCodeList?state=${formData.residentAddress.state}&city=${formData.residentAddress.city}`);
        const pinCodesData =  await response.json();
        setPincodeList(pinCodesData);
    } catch (error){
        console.error("Exception while fetching state lists.",error)
    }
  }


  useEffect(() => {
    fetchStateList();
    fetchAddressTypeOptions();
  }, []);

  const { address1, address2, address3, city, state, pincode, country, residencePhone, residenceFax, officePhone, officeFax, addrType } = formData.residentAddress;
  //const { addrType } = formData;

  return (
    <section style={{ width: '85%', margin: '40px auto' }}>
    <Grid container spacing={2}>
    <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="addrType-type">Address Type</InputLabel>
          <Select
            labelId="addrType-type"
            id="addrType-type"
            name="residentAddress.addrType"
            value={addrType}
            onChange={handleChange}
            label="Address Type"
            sx={{ borderRadius: '15px' }}
          >
            {addressypeList && addressypeList.map((option, index) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Address Line 1"
          name="residentAddress.address1"
          value={address1}
          onChange={handleChange}
          required
          error={!!errors.address1}
          helperText={errors.address1}
          InputProps={{
            sx: { borderRadius: '15px' },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Address Line 2"
          name="residentAddress.address2"
          value={address2}
          onChange={handleChange}
          required
          error={!!errors.address2}
          helperText={errors.address2}
          InputProps={{
            sx: { borderRadius: '15px' },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Address Line 3"
          name="residentAddress.address3"
          value={address3}
          onChange={handleChange}
          InputProps={{
            sx: { borderRadius: '15px' },
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <InputLabel id="residentAddress.country">Country</InputLabel>
          <Select
            labelId="residentAddress.country"
            id="residentAddress.country"
            name="residentAddress.country"
            value={country}
            onChange={handleChange}
            label="Country"
            sx={{ borderRadius: '15px' }}
          >
            {countryList && countryList.map((option, index) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <InputLabel id="residentAddress.state">State</InputLabel>
          <Select
            labelId="residentAddress.state"
            id="residentAddress.state"
            name="residentAddress.state"
            value={state}
            onChange={populateCityList}
            label="State"
            sx={{ borderRadius: '15px' }}
          >
            {stateList && stateList.map((option, index) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {/* <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="State"
          name="residentAddress.state"
          value={state}
          onChange={handleChange}
          required
          error={!!errors.state}
          helperText={errors.state}
          InputProps={{
            sx: { borderRadius: '15px' },
          }}
        />
      </Grid> */}
      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <InputLabel id="residentAddress.city">City</InputLabel>
          <Select
            labelId="residentAddress.city"
            id="residentAddress.city"
            name="residentAddress.city"
            value={city}
            onChange={populatePinCodeList}
            label="city"
            sx={{ borderRadius: '15px' }}
          >
            {cityList && cityList.map((option, index) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {/* <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="City"
          name="residentAddress.city"
          value={city}
          onChange={handleChange}
          required
          error={!!errors.city}
          helperText={errors.city}
          InputProps={{
            sx: { borderRadius: '15px' },
          }}
        />
      </Grid> */}
      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <InputLabel id="residentAddress.pincode">Pincode</InputLabel>
          <Select
            labelId="residentAddress.pincode"
            id="residentAddress.pincode"
            name="residentAddress.pincode"
            value={pincode}
            onChange={handleChange}
            label="city"
            sx={{ borderRadius: '15px' }}
          >
            {pincodeList && pincodeList.map((option, index) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {/* <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="Pincode"
          name="residentAddress.pincode"
          value={pincode}
          onChange={handleChange}
          required
          error={!!errors.pincode}
          helperText={errors.pincode}
          InputProps={{
            sx: { borderRadius: '15px' },
          }}
        />
      </Grid> */}
      {/* <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="Country"
          name="residentAddress.country"
          value={country}
          onChange={handleChange}
          required
          error={!!errors.country}
          helperText={errors.country}
          InputProps={{
            sx: { borderRadius: '15px' },
          }}
        />
      </Grid> */}
      {/* <Grid item xs={12}  md={6}>
        <TextField
          fullWidth
          label="Residence Phone"
          type="number"
          name="residentAddress.residencePhone"
          value={residencePhone}
          onChange={handleChange}
          required
          error={!!errors.residencePhone}
          helperText={errors.residencePhone}
        />
      </Grid>
      <Grid item xs={12}  md={6}>
        <TextField
          fullWidth
          label="Residence Fax"
          type="number"
          name="residentAddress.residenceFax"
          value={residenceFax}
          onChange={handleChange}
          required
          error={!!errors.residenceFax}
          helperText={errors.residenceFax}
        />
      </Grid>
      <Grid item xs={12}  md={6}>
        <TextField
          fullWidth
          label="Office Phone"
          type="number"
          name="residentAddress.officePhone"
          value={officePhone}
          onChange={handleChange}
          required
          error={!!errors.officePhone}
          helperText={errors.officePhone}
        />
      </Grid>
      <Grid item xs={12}  md={6}>
        <TextField
          fullWidth
          label="Office Fax"
          type="number"
          name="residentAddress.officeFax"
          value={officeFax}
          onChange={handleChange}
          required
          error={!!errors.officeFax}
          helperText={errors.officeFax}
        />
      </Grid> */}
    </Grid>
    </section>
  );
};

export default ResidentAddressInformation;
