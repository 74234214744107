import React, { useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { baseUrl } from '../../utils/baseUrl';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../Context/UserContext';
import { CircularProgress, Box,Dialog,DialogContentText,DialogContent } from '@mui/material';

import './sso.style.css'

const LoginWithGoogle = ({ handleOTPModel, setEmail }) => {
    const navigate = useNavigate()
    const { setUserInfo } = useUser();
    const [loading, setLoading] = useState(false)

    const login = useGoogleLogin({
        onSuccess: async tokenResponse => {
            try {
                const credential = tokenResponse.access_token;
                const response = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${credential}`
                    }
                });

                const data = await response.json();
                console.log("User Data:", data);

                if (response.ok) {
                    try {
                        const userData = {
                            email: data.email,
                            verificationActionType: "Login",
                        };
                        setEmail(data?.email)
                        setLoading(true);
                        const response = await fetch(`${baseUrl}/user/generateAndSendLoginOTP`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(userData),
                        });

                        const responseData = await response.json();
                        if (!response.ok) {
                            console.error("Error in login", responseData);
                        }
                        setLoading(false);
                        console.log("responseData", responseData)
                        handleOTPModel()
                    } catch (error) {
                        console.error("Error in login", error);
                    }
                } else {
                    console.error('Error fetching user info:', data);
                }
            } catch (error) {
                console.error('Network error:', error);
            }
        },
        onError: error => {
            console.error('Login Failed:', error);
        },
    });

    return (

        <>
            <section>
                {loading && (
                    <Dialog open={loading}>
                        <DialogContent>
                            <DialogContentText>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <CircularProgress />
                                </Box>
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>

                )}
            </section>

            <h1 className='heading-align'>Welcome To Niveshiq</h1>
            <br />
            <button className="google-login-btn" onClick={() => login()}>
                <img src="/assets/logo/free-google.png" alt="google-login" />
                Continue With Google
            </button>
            <br />
            <h2 className='bottom-align'>Or</h2>
        </>

    );
};

export default LoginWithGoogle;
