import React, { useEffect, useState } from 'react';
import { getWatchList, removeFromWatchList } from '../utils/watchList';
import { useNavigate } from 'react-router-dom';
import { Box, Paper, Grid } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Searchbar from '../Layout/SearchBar';
import { baseUrl } from '../utils/baseUrl';
import DashboardSideBar from '../Layout/DashboardSideBar';

const WatchlistPage = () => {

    const [currentPage, setCurrentPage] = useState(1);
    const [uniqueClientCode,setUniqueClientCode] = useState('');
    const [watchLists, setWatchLists] = useState([]);
    const [mFwatchListElementDTOS, setMFwatchListElementDTOS] = useState([]);
    const [watchListCount, setWatchListCount] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        getWatchLists();
    },[currentPage]);

    //const watchList = getWatchList();

    const getWatchLists = async () => {
        try {
            const uniqueClientCode=JSON.parse(window.localStorage.getItem("userInfo"))?.clientCode || "";
            // const uniqueClientCode="L1R5G8";
            const response = await fetch(`${baseUrl}/user/watchList?uniqueClientCode=${uniqueClientCode}&page=${currentPage}&size=15`);
            if (!response.ok) {
                throw new Error("An error occurred");
            }

            const data = await response.json();
            setWatchLists(data.mfProductResponseElementDTOList);
            setWatchListCount(data.mfProductResponseElementDTOList?data.mfProductResponseElementDTOList.length:0);
        } catch (error) {
            console.error("Error fetching mutual funds:", error);
        }
    };

    const handleRemove = async(mfProductId) => {
        alert("Handling remove watchlist.." + mfProductId);
        // const uniqueClientCode=JSON.parse(window.localStorage.getItem("userInfo")).clientCode
        // if (!uniqueClientCode){
        //     navigate("/login")
        // }
        const watchListData = {
            uniqueClientCode: uniqueClientCode,
            mfProductID: mfProductId,
            status: false,
        };
        const response= await fetch(`${baseUrl}/user/add-to-watchlist`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(watchListData)
        });
        const data = await response.json();
        console.log("Printing add to watchlist response....", data);
        if(data.status == true){
            getWatchLists();
        }
    };

    const showMfInfo = (mfProductId) => {
        console.log("mfProductId", mfProductId)
        navigate(`/mf/product-info/${mfProductId}`)
    }

    const handleInvetNow = async()=>{
        alert("invest now")
    }

    return (
        <>
            <Searchbar />
            {/* <Box sx={{ padding: '20px' }}>
                <h2>Watchlist</h2>
                <List>
                    {watchList.map(item => (
                        <Paper elevation={3} sx={{ marging: "10px 0px" }} >
                            <ListItem key={item.mfProductId}>
                                <ListItemText
                                    primary={item.mfProductName}
                                    secondary={item.mfProductId}
                                />
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="delete" onClick={() => handleRemove(item.mfProductId)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </Paper>
                    ))}
                </List>
            </Box> */}
            <h1>Watchlists ({watchListCount})</h1>
            <br></br>
            {watchListCount>0 && <h2>Here are the funds you have shortlisted.</h2>}
            {watchListCount == 0 && <h2>No item added in watchlists.</h2>}
            <br></br>

            <Box p={2} sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px"
            }}>
                {watchLists.map((item, index) => (
                    <Paper key={index} sx={{ padding: "10px 5px" }}>
                        <Grid container>
                            <Grid item sx={12} md={9} onClick={() => showMfInfo(item.mfProductId)}>
                                <Box sx={{ display: "flex", gap: "20px" }}>
                                    <img src="/assets/logo/mutual_fund.png" alt="mutual-fund" width={30} height={30} />
                                    <Box sx={{ width: "100%" }}>
                                        <h5>{item.mfProductName}</h5>
                                        <section className='mutual-fund-des'>
                                            <div className='desc-section'>
                                            <span>Average-AUM(Apr-Jun 2024) ₹{item.aum} lkhs</span>
                                                <span>{item.mfCategory}</span>
                                                <span>{item.mfSubCategory}</span>
                                                <span>{item.dividendtype}</span>
                                                <span>{item.dividendPlan}</span>
                                            </div>
                                        </section>
                                    </Box>
                                </Box>

                            </Grid>
                            <Grid item xs={12} md={3} sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between"
                            }}>
                                <Box sx={{display:"flex",justifyContent:"center"}}>
                                    <button className='invest-now' onClick={() => handleInvetNow(item.mfProductId, item.mfProductName)}>
                                        Invest
                                    </button>
                                </Box>
                                <Box sx={{display:"flex",justifyContent:"center"}}>
                                    <button className='remove-watchlist' onClick={() => handleRemove(item.mfProductId)}>
                                        Remove 
                                    </button>
                                </Box>
                            </Grid>
                        </Grid>


                    </Paper>
                ))}
            </Box>

        </>
    );
};

export default WatchlistPage;
