import React, { useState, useCallback ,useEffect} from 'react';
import { List, ListItem, ListItemText, Collapse, Checkbox,Box,Button } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useSidebar } from '../Context/SidebarContext';
import { baseUrl } from '../utils/baseUrl';

const StyledLink = styled(Link)({
  textDecoration: 'none',
  color: 'inherit',
});

const SidebarContainer = styled('div')(({ theme }) => ({
  width: 250,
  height: '100vh',
  top: 20,
  left: 0,
  overflowY: 'auto',
  msOverflowStyle: 'none',  // For IE and Edge
  scrollbarWidth: 'none',   // For Firefox
  '&::-webkit-scrollbar': {
    display: 'none',        // For Chrome, Safari, and Opera
  }
}));

const SectionContainer = styled('div')({
  marginBottom: 6,
  borderBottom: "2px solid #ececec"
});

const CustomListItemText = styled(ListItemText)`
  & .MuiTypography-root {
    font-size: 12px;
  }
`;

const Sidebar = React.memo(() => {
  const location = useLocation();
  const { openSections, selectedFilters, handleSectionToggle, handleFilterToggle } = useSidebar();
  const [dividendType, setDividentType] = useState([])
  const [categories, setCategories] = useState([])
  const [subCategoriesType, setSubCategoriesTYpe] = useState([])
  const [amcType, setAmcType] = useState([])
  const [dividendPlans, setDividendPlans] = useState([]);
  const [riskLevels, setRiskLevels] = useState([]);
  const [returnLevels, setReturnLevels] = useState([]);



  const getSelectedMenuText = () => {
    switch (location.pathname) {
      case '/mutual-funds':
      case '/mutual-funds/sips':
      case '/mutual-funds/orders':
      case '/mutual-funds/explore':
      case '/mutual-funds/holdings':
      case '/mutual-funds/swp':
        return 'Mutual Funds';
      case '/dashboard':
        return 'Dashboard';
      case '/distributor':
        return "Distributor's Portal";
      default:
        return 'Menu';
    }
  };

  const getAMCTypes = async () => {
    try {
      const response = await fetch(`${baseUrl}/amc/info`, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json'
        },
      });

      if (!response.ok) {
        throw new Error("An error occurred");
      }

      const data = await response.json();
      setAmcType(data);
    } catch (error) {
      console.error("Error fetching mutual funds:", error);
    }
  };
  const getCategoryTypes = async () => {
    try {
      const response = await fetch(`${baseUrl}/products/mutual-funds/categories`, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json'
        },
      });

      if (!response.ok) {
        throw new Error("An error occurred");
      }

      const data = await response.json();
      setCategories(data);
    } catch (error) {
      console.error("Error fetching mutual funds:", error);
    }
  };

  const getSubCategoryTypes = async () => {
    try {
      const bodyData={
        productCategories:[]
    }
      const response = await fetch(`${baseUrl}/products/mutual-funds/subcategories`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body:JSON.stringify(bodyData)
      });

      if (!response.ok) {
        throw new Error("An error occurred");
      }

      const data = await response.json();
      setSubCategoriesTYpe(data);
    } catch (error) {
      console.error("Error fetching mutual funds:", error);
    }
  };
  const getDividendTypes = async () => {
    try {
      const response = await fetch(`${baseUrl}/products/mutual-funds/dividendType`, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json'
        },
      });

      if (!response.ok) {
        throw new Error("An error occurred");
      }

      const data = await response.json();
      setDividentType(data);
    } catch (error) {
      console.error("Error fetching mutual funds:", error);
    }
  };

  const getDividendPlans = async () => {
    try {
      const response = await fetch(`${baseUrl}/products/mutual-funds/dividendPlan`, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json'
        },
      });

      if (!response.ok) {
        throw new Error("An error occurred");
      }

      const data = await response.json();
      setDividendPlans(data);
    } catch (error) {
      console.error("Error fetching mutual funds:", error);
    }
  };

  const getRiskLevels = async () => {
    try {
      const response = await fetch(`${baseUrl}/products/mutual-funds/riskLevel`, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json'
        },
      });

      if (!response.ok) {
        throw new Error("An error occurred");
      }

      const data = await response.json();
      setRiskLevels(data);
    } catch (error) {
      console.error("Error fetching mutual funds:", error);
    }
  };

  const getReturnLevels = async () => {
    try {
      const response = await fetch(`${baseUrl}/products/mutual-funds/returnLevel`, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json'
        },
      });

      if (!response.ok) {
        throw new Error("An error occurred");
      }

      const data = await response.json();
      setReturnLevels(data);
    } catch (error) {
      console.error("Error fetching mutual funds:", error);
    }
  };

  useEffect(() => {
    getAMCTypes()
    getCategoryTypes()
    getSubCategoryTypes()
    getDividendTypes();
    getDividendPlans();
    getRiskLevels();
    getReturnLevels();
  }, []);

  return (
    <SidebarContainer>
      <SectionContainer>
        <List component="nav">
          <ListItem sx={{
            margin: "20px 6px 40px 2px",
            borderRadius: "7px",
            backgroundColor: "aliceblue",
          }} onClick={() => handleSectionToggle('mutualFunds')}>
            <ListItemText primary={getSelectedMenuText()} />
            {openSections.mutualFunds ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openSections.mutualFunds} timeout="auto" unmountOnExit>
            <List component="div" disablePadding >
              <StyledLink to="/mutual-funds/explore">
                <ListItem sx={{ pl: 4, backgroundColor: location.pathname === '/mutual-funds' ? '#d0d0d0' : 'inherit' }}>
                  <ListItemText primary="Mutual Funds" />
                </ListItem>
              </StyledLink>
              <StyledLink to="/dashboard">
                <ListItem sx={{ pl: 4, backgroundColor: location.pathname === '/dashboard' ? '#d0d0d0' : 'inherit' }}>
                  <ListItemText primary="Dashboard" />
                </ListItem>
              </StyledLink>
              <StyledLink to="/distributor/clients">
                <ListItem sx={{ pl: 4, backgroundColor: location.pathname === '/distributor' ? '#d0d0d0' : 'inherit' }}>
                  <ListItemText primary="Distributor's Portal" />
                </ListItem>
              </StyledLink>
            </List>
          </Collapse>
        </List>
      </SectionContainer>

      <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",padding: "5px 10px"}}>
        <span>Filters</span>
        <span style={{color:"#FE7E57"}}>Clear All</span>
      </Box>
      <SectionContainer>
        <List component="nav">
          <ListItem onClick={() => handleSectionToggle('amcs')}>
            <ListItemText primary="AMC" />
            {openSections.amcs ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openSections.amcs} timeout="auto" unmountOnExit>
            <List component="div" disablePadding sx={{ maxHeight: 320, overflowY: 'auto' }}>
              {amcType && amcType.map((amcs) => (
                <ListItem key={amcs} sx={{ pl:1 }}>
                  <Checkbox
                    checked={selectedFilters.amcs.includes(amcs)}
                    onChange={() => handleFilterToggle('amcs', amcs)}
                  />
                  <CustomListItemText primary={amcs} />
                </ListItem>
              ))}
            </List>
          </Collapse>
        </List>
      </SectionContainer>

      <SectionContainer>   
        <List component="nav">
          <ListItem onClick={() => handleSectionToggle('productCategories')}>
            <ListItemText primary="Category" />
            {openSections.productCategories ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openSections.productCategories} timeout="auto" unmountOnExit>
            <List component="div" disablePadding sx={{ maxHeight: 320, overflowY: 'auto' }}>
              {categories && categories?.map((category) => (
                <ListItem key={category} sx={{ pl: 4 }}>
                  <Checkbox
                    checked={selectedFilters.productCategories.includes(category)}
                    onChange={() => handleFilterToggle('productCategories', category)}
                  />
                  <CustomListItemText primary={category} />
                </ListItem>
              ))}
            </List>
          </Collapse>
        </List>
      </SectionContainer>

      <SectionContainer>
        <List component="nav">
          <ListItem onClick={() => handleSectionToggle('productSubCategories')}>
            <ListItemText primary="Sub Category" />
            {openSections.productSubCategories ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openSections.productSubCategories} timeout="auto" unmountOnExit>
            <List component="div" disablePadding sx={{ maxHeight: 320, overflowY: 'auto' }}>
              {subCategoriesType && subCategoriesType.map((subCategory) => (
                <ListItem key={subCategory} sx={{ pl: 4 }}>
                  <Checkbox
                    checked={selectedFilters.productSubCategories.includes(subCategory)}
                    onChange={() => handleFilterToggle('productSubCategories', subCategory)}
                  />
                  <CustomListItemText primary={subCategory} />
                </ListItem>
              ))}
            </List>
          </Collapse>
        </List>
      </SectionContainer>

      <SectionContainer>
        <List component="nav">
          <ListItem onClick={() => handleSectionToggle('dividendType')}>
            <ListItemText primary="Dividend Type" />
            {openSections.dividendType ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openSections.dividendType} timeout="auto" unmountOnExit>
            <List component="div" disablePadding sx={{ maxHeight: 300, overflowY: 'auto' }}>
              {dividendType && dividendType?.map((dividendType) => (
                <ListItem key={dividendType} sx={{ pl: 4 }}>
                  <Checkbox
                    checked={selectedFilters.dividendType.includes(dividendType)}
                    onChange={() => handleFilterToggle('dividendType', dividendType)}
                  />
                  <ListItemText primary={dividendType} />
                </ListItem>
              ))}
            </List>
          </Collapse>
        </List>
      </SectionContainer>

      <SectionContainer>
        <List component="nav">
          <ListItem onClick={() => handleSectionToggle('dividendPlan')}>
            <ListItemText primary="Dividend Plan" />
            {openSections.dividendPlan ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openSections.dividendPlan} timeout="auto" unmountOnExit>
            <List component="div" disablePadding sx={{ maxHeight: 320, overflowY: 'auto' }}>
              {dividendPlans && dividendPlans?.map((dividendPlan) => (
                <ListItem key={dividendPlan} sx={{ pl: 4 }}>
                  <Checkbox
                    checked={selectedFilters.dividendPlans.includes(dividendPlan)}
                    onChange={() => handleFilterToggle('dividendPlans', dividendPlan)}
                  />
                  <ListItemText primary={dividendPlan}/>
                </ListItem>
              ))}
            </List>
          </Collapse>
        </List>
      </SectionContainer>

      <SectionContainer>
        <List component="nav">
          <ListItem onClick={() => handleSectionToggle('riskLevels')}>
            <ListItemText primary="Risk Levels" />
            {openSections.riskLevels ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openSections.riskLevels} timeout="auto" unmountOnExit>
            <List component="div" disablePadding sx={{ maxHeight: 320, overflowY: 'auto' }}>
              {riskLevels && riskLevels?.map((riskLevel) => (
                <ListItem key={riskLevel} sx={{ pl: 4 }}>
                  <Checkbox
                    checked={selectedFilters.riskLevels.includes(riskLevel)}
                    onChange={() => handleFilterToggle('riskLevels', riskLevel)}
                  />
                  <ListItemText primary={riskLevel}/>
                </ListItem>
              ))}
            </List>
          </Collapse>
        </List>
      </SectionContainer>

      <SectionContainer>
        <List component="nav">
          <ListItem onClick={() => handleSectionToggle('returnLevels')}>
            <ListItemText primary="Return Levels" />
            {openSections.returnLevels ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openSections.returnLevels} timeout="auto" unmountOnExit>
            <List component="div" disablePadding sx={{ maxHeight: 320, overflowY: 'auto' }}>
              {returnLevels && returnLevels?.map((returnLevel) => (
                <ListItem key={returnLevel} sx={{ pl: 4 }}>
                  <Checkbox
                    checked={selectedFilters.returnLevels.includes(returnLevel)}
                    onChange={() => handleFilterToggle('returnLevels', returnLevel)}
                  />
                  <ListItemText primary={returnLevel}/>
                </ListItem>
              ))}
            </List>
          </Collapse>
        </List>
      </SectionContainer>

      {/* <SectionContainer>
        <List component="nav">
          <ListItem onClick={() => handleSectionToggle('expenseRatio')}>
            <ListItemText primary="Expense Ratio" />
            {openSections.expenseRatio ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openSections.expenseRatio} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {['Ratio 1', 'Ratio 2', 'Ratio 3'].map((expenseRatio) => (
                <ListItem key={expenseRatio} sx={{ pl: 4 }}>
                  <Checkbox
                    checked={selectedFilters.expenseRatio.includes(expenseRatio)}
                    onChange={() => handleFilterToggle('expenseRatio', expenseRatio)}
                  />
                  <ListItemText primary={expenseRatio} />
                </ListItem>
              ))}
            </List>
          </Collapse>
        </List>
      </SectionContainer>

      <SectionContainer>
        <List component="nav">
          <ListItem onClick={() => handleSectionToggle('aum')}>
            <ListItemText primary="AUM" />
            {openSections.aum ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openSections.aum} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {['Aum1',"Aum2"].map((aum) => (
                <ListItem key={aum} sx={{ pl:1 }}>
                  <Checkbox
                    checked={selectedFilters.aum.includes(aum)}
                    onChange={() => handleFilterToggle('aum', aum)}
                  />
                  <ListItemText primary={aum} />
                </ListItem>
              ))}
            </List>
          </Collapse>
        </List>
      </SectionContainer>

      <SectionContainer>
        <List component="nav">
          <ListItem onClick={() => handleSectionToggle('minPurchaseAmount')}>
            <ListItemText primary="Min. Purchase Amount" />
            {openSections.minPurchaseAmount ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openSections.minPurchaseAmount} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {['Amount 1', 'Amount 2', 'Amount 3'].map((amount) => (
                <ListItem key={amount} sx={{ pl: 4 }}>
                  <Checkbox
                    checked={selectedFilters.minPurchaseAmount.includes(amount)}
                    onChange={() => handleFilterToggle('minPurchaseAmount', amount)}
                  />
                  <ListItemText primary={amount} />
                </ListItem>
              ))}
            </List>
          </Collapse>
        </List>
      </SectionContainer> */}
    </SidebarContainer>
  );
});

export default Sidebar;
