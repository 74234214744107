import React ,{ useEffect, useState } from 'react';
import { Box, Card, CardContent, Typography, Grid, Avatar, Divider, Button } from '@mui/material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import HomeIcon from '@mui/icons-material/Home';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ClassIcon from '@mui/icons-material/Class';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import BusinessIcon from '@mui/icons-material/Business';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import PersonIcon from '@mui/icons-material/Person';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import SecurityIcon from '@mui/icons-material/Security';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import StorageIcon from '@mui/icons-material/Storage';
import GavelIcon from '@mui/icons-material/Gavel';
import { useNavigate } from 'react-router-dom';
import { baseUrl } from '../utils/baseUrl';
import { useUser } from '../Context/UserContext';

const ProfileCard = () => {

    const navigate = useNavigate();
    const intialFormState = {
        mfAccountHoldingInfoDTO :{},
        mfAccountHolderBankInfoDTO : {},
        fatcaUploadDetails : {},
        residentAddress :{},
        nominee1 : {},
        nominee2 : {},
        nominee3 : {},
        uniqueClientCode : ""
    }
    const [profileData, setProfileData] = useState(intialFormState);
    const {setUserInfo} = useUser();
    const [loading, setLoading] = useState(false)
    const userInfo = JSON.parse(window.localStorage.getItem("userInfo"));

    useEffect(() => {
        if (!userInfo) {
            navigate("/login")
        }
        getProfileView();
    },[])

    const getProfileView = async () => {
        try {
            const uniqueClientCode=JSON.parse(window.localStorage.getItem("userInfo"))?.clientCode || "";
            const response = await fetch(`${baseUrl}/user/getMFCustomerInfo?uniqueClientCode=${uniqueClientCode}`);
            if (!response.ok) {
                throw new Error("An error occurred");
            }
            const data = await response.json();
            console.log("Printing data", data);
             setProfileData(data);
        } catch (error) {
            console.error("Error fetching mutual funds:", error);
        }
    };

    const handleClientRegister = async(e) => {
        e.preventDefault();
        const uniqueClientCode=JSON.parse(window.localStorage.getItem("userInfo"))?.clientCode || "";
        const payload = {
          uniqueClientCode:uniqueClientCode
        }
        try {
            setLoading(true);
            const response = await fetch(`${baseUrl}/user/submitInvestorDetailsToPlatform`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload)
            });
            const data = await response.json();
            console.log("Submit client details to platform.", data);
            if(data.status == true){
                setUserInfo(
                    {
                        ...userInfo,
                        isInvestorRegistered:true
                    }
                )
                const userData = userInfo;
                window.localStorage.setItem("userInfo", JSON.stringify(userData));
                navigate("/mutual-funds/explore");
                setLoading(false);
            } else {
                setLoading(false);
                throw new Error("Something went wrong");
            }    
        } catch (error) {
            setLoading(false);
        }
      }

    return (
        <Box sx={{ p: 4, minHeight: '100vh', display: "flex", flexDirection: "column", gap: "30px" }}>
            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} gap={3} justifyContent="center">
                <Card sx={{ borderRadius: 2, flex: 1 }}>
                    <CardContent>
                        <Typography variant="h5" gutterBottom>
                            {profileData.mfAccountHoldingInfoDTO.firstName} {profileData.mfAccountHoldingInfoDTO.middleName} {profileData.mfAccountHoldingInfoDTO.lastName}
                        </Typography>
                        <Typography variant="body1" color="text.secondary">
                            DOB: {profileData.mfAccountHoldingInfoDTO.dob}
                        </Typography>
                        <Typography variant="body1" color="text.secondary">
                            Mobile: {profileData.mfAccountHoldingInfoDTO.mobileNumber}
                        </Typography>

                        <Box display="flex" alignItems="center" mb={3}>
                            <ContactMailIcon sx={{ mr: 1, color: 'primary.main' }} />
                            <Typography variant="body2">
                                <strong>Email:</strong> {profileData.mfAccountHoldingInfoDTO.email}
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" mb={3}>
                            <CreditCardIcon sx={{ mr: 1, color: 'primary.main' }} />
                            <Typography variant="body2">
                                <strong>PAN:</strong> {profileData.mfAccountHoldingInfoDTO.pan}
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" mt={2}>
                            <HomeIcon sx={{ mr: 1, color: 'primary.main' }} />
                            <Typography variant="body2">
                                Address: {profileData.residentAddress.address1}, {profileData.residentAddress.city}, {profileData.residentAddress.state} - {profileData.residentAddress.pincode}, {profileData.residentAddress.country}
                            </Typography>
                        </Box>
                    </CardContent>
                </Card>

                <Card sx={{ borderRadius: 2, flex: 2 }}>
                    <CardContent>
                        <Card sx={{ borderRadius: 2, flex: 2 }}>
                            <CardContent>

                                {/* Bank Details Section */}
                                <Box display="grid" gridTemplateColumns={{ xs: '1fr', sm: 'repeat(2, 1fr)' }} gap={3}>
                                    <Box display="flex" alignItems="center">
                                        <AccountBalanceIcon sx={{ mr: 1, color: 'primary.main' }} />
                                        <Typography variant="body2">
                                            <strong>Bank:</strong> {profileData.mfAccountHolderBankInfoDTO.bankName}
                                        </Typography>
                                    </Box>
                                    <Box display="flex" alignItems="center">
                                        <CreditCardIcon sx={{ mr: 1, color: 'primary.main' }} />
                                        <Typography variant="body2">
                                            <strong>Account No:</strong> {profileData.mfAccountHolderBankInfoDTO.accountNumber}
                                        </Typography>
                                    </Box>
                                    <Box display="flex" alignItems="center">
                                        <ClassIcon sx={{ mr: 1, color: 'primary.main' }} />
                                        <Typography variant="body2">
                                            <strong>Account Type:</strong> {profileData.mfAccountHolderBankInfoDTO.accountType}
                                        </Typography>
                                    </Box>
                                    <Box display="flex" alignItems="center">
                                        <AccountBalanceWalletIcon sx={{ mr: 1, color: 'primary.main' }} />
                                        <Typography variant="body2">
                                            <strong>IFSC Code:</strong> {profileData.mfAccountHolderBankInfoDTO.ifscCode}
                                        </Typography>
                                    </Box>
                                    <Box display="flex" alignItems="center">
                                        <AccountBalanceWalletIcon sx={{ mr: 1, color: 'primary.main' }} />
                                        <Typography variant="body2">
                                            <strong>MICR Number:</strong> {profileData.mfAccountHolderBankInfoDTO.micrNumber}
                                        </Typography>
                                    </Box>
                                    <Box display="flex" alignItems="center">
                                        <BusinessIcon sx={{ mr: 1, color: 'primary.main' }} />
                                        <Typography variant="body2">
                                            <strong>Branch:</strong> {profileData.mfAccountHolderBankInfoDTO.branchName}
                                        </Typography>
                                    </Box>
                                </Box>

                                {/* Branch Address Section */}
                                <Box mt={3}>
                                    <Typography variant="h6" gutterBottom>
                                        Branch Address
                                    </Typography>
                                    <Box display="flex" alignItems="center" mb={2}>
                                        <HomeIcon sx={{ mr: 1, color: 'primary.main' }} />
                                        <Typography variant="body2">
                                            {profileData.mfAccountHolderBankInfoDTO.branchAddress1 && <span>{profileData.mfAccountHolderBankInfoDTO.branchAddress1}</span>}
                                            {profileData.mfAccountHolderBankInfoDTO.branchAddress2 && <span>, {profileData.mfAccountHolderBankInfoDTO.branchAddress2}</span>}
                                            {profileData.mfAccountHolderBankInfoDTO.branchAddress3 && <span>, {profileData.mfAccountHolderBankInfoDTO.branchAddress3}</span>}
                                            {profileData.mfAccountHolderBankInfoDTO.branchCity && <span>, {profileData.mfAccountHolderBankInfoDTO.branchCity}</span>}
                                            {profileData.mfAccountHolderBankInfoDTO.branchPincode && <span>, {profileData.mfAccountHolderBankInfoDTO.branchPincode}</span>}
                                            {profileData.mfAccountHolderBankInfoDTO.branchCountry && <span>, {profileData.mfAccountHolderBankInfoDTO.branchCountry}</span>}
                                        </Typography>
                                    </Box>
                                </Box>
                            </CardContent>
                        </Card>


                    </CardContent>
                </Card>
            </Box>

            <Divider sx={{ my: 2 }} />

            {/* FATCA Uploaded Details */}
            <Card sx={{ borderRadius: 2 }}>
                <CardContent>

                    <Typography variant="h6" gutterBottom>
                        Nominees
                    </Typography>
                    {profileData.nominee1 && [profileData.nominee1, profileData.nominee2, profileData.nominee3].map((nominee, index) => (
                        <Box key={index} mt={1} display="flex" alignItems="center">
                            <FamilyRestroomIcon sx={{ mr: 1, color: 'primary.main' }} />
                            <Typography variant="body2">
                                Name: {nominee?.name} | Relationship: {nominee?.relationShip} | Percentage: {nominee?.percentage}%
                            </Typography>
                        </Box>
                    ))}
                    <Divider sx={{ my: 2 }} />

                    <Typography sx={{ my: 2 }} >FATCA Uploaded Details</Typography>
                    <Divider sx={{ my: 2 }} />

                    <Box display="grid" gridTemplateColumns={{ xs: '1fr', sm: 'repeat(2, 1fr)' }} gap={3}>
                        {/* <Box display="flex" alignItems="center">
                            <PersonIcon sx={{ mr: 1, color: 'primary.main' }} />
                            <Typography variant="body2">
                                <strong>Investor Name:</strong> {profileData.fatcaUploadDetails.investorName}
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <CreditCardIcon sx={{ mr: 1, color: 'primary.main' }} />
                            <Typography variant="body2">
                                <strong>PAN Number:</strong> {profileData.fatcaUploadDetails.panNumber}
                            </Typography>
                        </Box> */}
                        <Box display="flex" alignItems="center">
                            <MonetizationOnIcon sx={{ mr: 1, color: 'primary.main' }} />
                            <Typography variant="body2">
                                <strong>Income Slab:</strong> {profileData.fatcaUploadDetails.incomeSlab}
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <SecurityIcon sx={{ mr: 1, color: 'primary.main' }} />
                            <Typography variant="body2">
                                <strong>Politically Exposed Person:</strong> {profileData.fatcaUploadDetails.politicallyExposedPerson}
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <AttachMoneyIcon sx={{ mr: 1, color: 'primary.main' }} />
                            <Typography variant="body2">
                                <strong>Source of Wealth:</strong> {profileData.fatcaUploadDetails.sourceWealth}
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <HomeIcon sx={{ mr: 1, color: 'primary.main' }} />
                            <Typography variant="body2">
                                <strong>Address Type:</strong> {profileData.fatcaUploadDetails.addrType}
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <StorageIcon sx={{ mr: 1, color: 'primary.main' }} />
                            <Typography variant="body2">
                                <strong>Data Source:</strong> {profileData.fatcaUploadDetails.dataSource}
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <GavelIcon sx={{ mr: 1, color: 'primary.main' }} />
                            <Typography variant="body2">
                                <strong>Tax Status:</strong> {profileData.fatcaUploadDetails.taxStatus}
                            </Typography>
                        </Box>
                        {/* <Box display="flex" alignItems="center">
                            <BusinessIcon sx={{ mr: 1, color: 'primary.main' }} />
                            <Typography variant="body2">
                                <strong>SP Name:</strong> {profileData.fatcaUploadDetails.spName}
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <PersonIcon sx={{ mr: 1, color: 'primary.main' }} />
                            <Typography variant="body2">
                                <strong>FR Name:</strong> {profileData.fatcaUploadDetails.frName}
                            </Typography>
                        </Box> */}
                    </Box>


                </CardContent>
            </Card>

            <Divider sx={{ my: 2 }} />
            <Card sx={{ borderRadius: 2 }}>
              <CardContent>
                <Box mt={3} display="flex" justifyContent="space-around">
                  {/* <Button variant="contained" color="primary" onClick={handleClientEdit}>
                    Download Registration Form
                  </Button> */}
                  <Button variant="contained" color="primary" onClick={handleClientRegister}>
                    Register
                  </Button>
                </Box>
              </CardContent>
            </Card>

        </Box>
    );

};

export default ProfileCard;

