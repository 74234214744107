import React, { useEffect, useState } from 'react';
import { baseUrl } from '../utils/baseUrl';
import { Paper, Box, Typography } from '@mui/material';
import { useSidebar } from '../Context/SidebarContext';
import './pages.style.css'

const Equity = () => {
  return (
    <div>
      <h1>Page 2</h1>
      <p>This is Page 2.</p>
    </div>
  );
};

export default Equity;
