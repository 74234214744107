import React, { useEffect, useState } from 'react';
import { TextField, Grid, Select, MenuItem, FormControl, InputLabel, Button } from '@mui/material';
import { baseUrl } from '../../utils/baseUrl';

const NomineeInformation = ({ formData, handleChange }) => {
  const [nomineeTypeList, setNomineeTypeList] = useState([]);
  const [nomineeRelationsList, setNomineeRelationsList] = useState([]);
  const [visibleNominees, setVisibleNominees] = useState(1); // Track the number of visible nominee forms

  const fetchNomineeTypeOptions = async () => {
    try {
      const response = await fetch(`${baseUrl}/config/nomineeTypes`);
      const data = await response.json();
      setNomineeTypeList(data);
    } catch (error) {
      console.error('Error fetching nominee type options:', error);
    }
  };

  const fetchNomineeRelationsOptions = async () => {
    try {
      const response = await fetch(`${baseUrl}/config/relationships`);
      const data = await response.json();
      setNomineeRelationsList(data);
    } catch (error) {
      console.error('Error fetching nominee relation options:', error);
    }
  };

  useEffect(() => {
    fetchNomineeTypeOptions();
    fetchNomineeRelationsOptions();
  }, []);

  const addMoreNominee = () => {
    setVisibleNominees(prev => Math.min(prev + 1, 3)); // Increment visible nominees up to 3
  };

  const removeNominee = () => {
    setVisibleNominees(prev => Math.max(prev - 1, 1)); // Decrement visible nominees down to 1
  };

  const renderNomineeForm = (nomineeNumber) => {
    const { type, name, relationShip, percentage } = formData[`nominee${nomineeNumber}`];
    return (
      <React.Fragment key={nomineeNumber}>
        <Grid item xs={12}>
          <h4>Nominee {nomineeNumber}</h4>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id={`nominee${nomineeNumber}-type`}>Nominee Type</InputLabel>
            <Select
              labelId={`nominee${nomineeNumber}-type`}
              id={`nominee${nomineeNumber}-type`}
              name={`nominee${nomineeNumber}.type`}
              value={type}
              onChange={handleChange}
              label="Nominee Type"
              sx={{ borderRadius: '15px' }}
            >
              {nomineeTypeList.map((option, index) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Name"
            name={`nominee${nomineeNumber}.name`}
            value={name}
            onChange={handleChange}
            InputProps={{
              sx: { borderRadius: '15px' },
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id={`nominee${nomineeNumber}-relationship`}>Relationship</InputLabel>
            <Select
              labelId={`nominee${nomineeNumber}-relationship`}
              id={`nominee${nomineeNumber}-relationship`}
              name={`nominee${nomineeNumber}.relationShip`}
              value={relationShip}
              onChange={handleChange}
              label="Relationship"
              sx={{ borderRadius: '15px' }}
            >
              {nomineeRelationsList.map((option, index) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Percentage"
            name={`nominee${nomineeNumber}.percentage`}
            value={percentage}
            onChange={handleChange}
            InputProps={{
              sx: { borderRadius: '15px' },
            }}
          />
        </Grid>
        {nomineeNumber === visibleNominees && nomineeNumber > 1 && (
          <Grid item xs={12}>
            <Button variant="contained" color="secondary" onClick={removeNominee}>
              - Remove
            </Button>
          </Grid>
        )}
      </React.Fragment>
    );
  };

  return (
    <section style={{ width: '85%', margin: '40px auto' }}>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h3>Enter up to 3 Nominees</h3>
      </Grid>
      {Array.from({ length: visibleNominees }).map((_, index) =>
        renderNomineeForm(index + 1)
      )}
      {visibleNominees < 3 && (
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={addMoreNominee}>
            + Add More
          </Button>
        </Grid>
      )}
    </Grid>
    </section>
  );
};

export default NomineeInformation;
