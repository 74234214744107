import React, { useState } from 'react';
import { Container, Stepper, Step, StepLabel, Button, Box, Paper, Typography } from '@mui/material';
import PrimaryHolderInformation from './PrimaryHolderInformation';
import BankDetailsInformation from './BankDetailsInformation';
import NomineeInformation from './NomineeInformation';
import ResidentAddressInformation from './ResidentAddressInformation';
import FatcaInformation from './FatcaInformation';
import GeneralInfo from './GeneralInfo';
import { baseUrl } from '../../utils/baseUrl';
import { styled } from '@mui/system';
import Searchbar from '../../Layout/SearchBar';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../Context/UserContext';

const initialFormState = {
  clientCode: '',
  primaryHolder: {
    firstName: '',
    middleName: '',
    lastName: '',
    dob: '',
    panExempt: 'N',
    pan: '',
    exemptCategory: '',
    mobileNumber: '',
    email: '',
    gender: ''
    // mobileRelation: '',
    // emailRelation: ''
  },
  accountInfo1: {
    accountType: '',
    accountNumber: '',
    ifscCode: '',
    defaultBank: 'N',
    micrNumber: '',
    bankName: '',
    branchName: '',
    branchAddress1: ''
  },
  nominee1: {
    type: '',
    name: '',
    relationShip: '',
    percentage: ''
  },
  nominee2: {
    type: '',
    name: '',
    relationShip: '',
    percentage: ''
  },
  nominee3: {
    type: '',
    name: '',
    relationShip: '',
    percentage: ''
  },
  residentAddress: {
    address1: '',
    address2: '',
    address3: '',
    city: '',
    state: '',
    pincode: '',
    country: '',
    residencePhone: '',
    residenceFax: '',
    officePhone: '',
    officeFax: '',
    addrType: ''
  },
  taxStatus: '',
  gender: '',
  occupation: '',
  holdingNature: '',
  fatcaDetails: {
    politicallyExposedPerson: '',
    incomeSlab: '',
    sourceWealth: ''
  }
};

const CustomStepper = styled(Stepper)({
  padding: '24px 0',
});

const CustomStepLabel = styled(StepLabel)(({ theme, ownerState }) => ({
  cursor: 'pointer',
  '& .MuiStepIcon-root': {
    color: ownerState.completed ? '#045172' : "#888",
  },
  '& .MuiStepLabel-label': {
    color: ownerState.active ? '#045172' : "#888",
  },
}));

const TextStepper = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '24px',
  padding: "0 30px",
});

const TextStep = styled(Typography)(({ theme, active }) => ({
  cursor: 'pointer',
  color: active ? '#1976d2' : "#718EBF",
  textDecoration: active ? 'underline' : 'none',
  fontWeight: active ? "900" : "600",
}));

const MultiStepForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialFormState);
  const [currentStep, setCurrentStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState([0]);
  const [RegistrationResponse, setRegistrationResponse] = useState();
  const [errors, setErrors] = useState({});
  const { userInfo } = useUser();
  const [panNumberExist, setPanNumberExist] = useState(false);
  const [loading, setLoading] = useState(false)
  const steps = [
    'Primary Holder',
    'Bank Details',
    'Resident Address',
    'Nominee',
    'FATCA Details',
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    const [section, field] = name.split('.');
    if (field) {
      setFormData({
        ...formData,
        [section]: {
          ...formData[section],
          [field]: value
        }
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  // const checkPanNumberExists = async (panNumber) => {
  //     try{
  //       const uniqueClientCode=JSON.parse(window.localStorage.getItem("userInfo"))?.clientCode || "";
  //       const response = await fetch(`${baseUrl}/user/panNumberExists?uniqueClientCode=${uniqueClientCode}&panNumber=${panNumber}`);
  //       if (!response.ok) {
  //         throw new Error("An error occurred");
  //       }
  //       const data = await response.json();
  //       if(data.panNumberExist === true){
  //         setPanNumberExist(true);
  //       }
  //     } catch(error){
  //       console.error("Error while checking pan number"+ error);
  //       return false;
  //     }
  // }

  const validateStep = async () => {
    const newErrors = {};
    switch (currentStep) {
      case 0:
        if (!formData.primaryHolder.firstName) newErrors.firstName = 'First Name is required';
        if (!formData.primaryHolder.lastName) newErrors.lastName = 'Last Name is required';
        if (!formData.primaryHolder.dob) newErrors.dob = 'Date of Birth is required';
        if (!formData.primaryHolder.pan) newErrors.pan = 'PAN is required';
        const response = await fetch(`${baseUrl}/user/panNumberExists?panNumber=${formData.primaryHolder.pan}`);
        if (!response.ok) {
          throw new Error("An error occurred");
        }
        const data = await response.json();
        if(data.panNumberExist === true){
          setPanNumberExist(true);
        }
        if(panNumberExist === true){
          newErrors.pan = 'Pan number is laready registered in the system.'
        }
        if (!formData.primaryHolder.mobileNumber) newErrors.mobileNumber = 'Mobile Number is required';
        // if (!formData.primaryHolder.mobileRelation) newErrors.mobileRelation = 'Mobile relation is required';
        if (!formData.primaryHolder.email) newErrors.email = 'Email is required';
        // if (!formData.primaryHolder.emailRelation) newErrors.emailRelation = 'Email Relation is required';
        break;
      case 1:
        if (!formData.accountInfo1.accountType) newErrors.accountType = 'Account Type is required';
        if (!formData.accountInfo1.accountNumber) newErrors.accountNumber = 'Account Number is required';
        if (!formData.accountInfo1.ifscCode) newErrors.ifscCode = 'IFSC Code is required';
        if (!formData.accountInfo1.bankName) newErrors.bankName = 'Bank Name is required';
        if (!formData.accountInfo1.branchName) newErrors.branchName = 'Branch Name is required';
        if (!formData.accountInfo1.branchAddress1) newErrors.branchAddress1 = 'Branch Address1 is required';
        break;
      case 2:
        if (!formData.residentAddress.address1) newErrors.address1 = 'Address1 is required';
        if (!formData.residentAddress.address2) newErrors.address2 = 'Address2 is required';
        if (!formData.residentAddress.city) newErrors.city = 'City is required';
        if (!formData.residentAddress.state) newErrors.state = 'State is required';
        if (!formData.residentAddress.pincode) newErrors.pincode = 'Pincode is required';
        if (!formData.residentAddress.country) newErrors.country = 'Country is required';
        // if (!formData.residentAddress.residencePhone) newErrors.residencePhone = 'Residence Phone is required';
        // if (!formData.residentAddress.residenceFax) newErrors.residenceFax = 'Residence Fax is required';
        // if (!formData.residentAddress.officePhone) newErrors.officePhone = 'Office Phone is required';
        // if (!formData.residentAddress.officeFax) newErrors.officeFax = 'Office Fax is required';
        break;
      default:
        break;
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = async () => {
    if (validateStep()) {
      if (currentStep === steps.length - 1) {
        const handleRegistrationSubmit = async () => {
          try {
            const uniqueClientCode = JSON.parse(window.localStorage.getItem("userInfo"))?.clientCode;
            if (!uniqueClientCode) {
              alert("Login first")
              return navigate("/login");
            }
            formData.clientCode = uniqueClientCode;
            // console.log("formData",formData)
            const response = await fetch(`${baseUrl}/user/saveMFCustomerInfo`, {
              method: "POST",
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(formData)
            });

            if (!response.ok) {
              throw new Error("An error occurred");
            }

            const data = await response.json();
            if(data.status === true){
              const mfInvestorInfo = data.mfInvestorInformationDTO;
              navigate('/registration-overview', { state: { mfInvestorInfo } });
            }

          } catch (error) {
            console.error("Error fetching mutual funds:", error);
          }
        };
        handleRegistrationSubmit();
      } else {
        if(currentStep == 0){
          const newErrors = {};
          const response = await fetch(`${baseUrl}/user/panNumberExists?panNumber=${formData.primaryHolder.pan}`);
          if (!response.ok) {
            throw new Error("An error occurred");
          }
          const data = await response.json();
          if(data.panNumberExist === true){
            setPanNumberExist(true);
          }
          if(panNumberExist === true){
            newErrors.pan = 'Pan number is already registered in the system.'
          }
          setErrors(newErrors);
        }

        if (!completedSteps.includes(currentStep)) {
          setCompletedSteps([...completedSteps, currentStep]);
        }
        setCurrentStep((prevStep) => prevStep + 1);
      }
    }
  };

  const handleBack = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleStepClick = (step) => {
    if (completedSteps.includes(step) || step === currentStep) {
      setCurrentStep(step);
    }
  };

  const isStepComplete = (step) => {
    return true;
  };

  const StepComponent = [PrimaryHolderInformation, BankDetailsInformation, ResidentAddressInformation, NomineeInformation, FatcaInformation, GeneralInfo][currentStep];

  return (
    <>
      <Searchbar />
      <Container>
        <Paper elevation={3} sx={{ padding: "10px", margin: "10px 0px", borderRadius: "24px", padding: "47px 0" }}>
          <Box sx={{
            padding:"30px 30px"
          }}>
            <Typography>
            Tip: Take a moment to double-check all your details. If you need to make any changes, use the "Back" button to return to the previous steps.
            </Typography>
          </Box>
          <TextStepper>
            {steps.map((label, index) => (
              <TextStep
                key={label}
                onClick={() => handleStepClick(index)}
                active={currentStep === index}
              >
                {label}
              </TextStep>
            ))}
          </TextStepper>
          <CustomStepper activeStep={currentStep} alternativeLabel>
            {steps.map((label, index) => (
              <Step key={label} completed={completedSteps.includes(index)}>
                <CustomStepLabel
                  onClick={() => handleStepClick(index)}
                  completed={completedSteps.includes(index)}
                  ownerState={{
                    completed: completedSteps.includes(index),
                    active: currentStep === index
                  }}
                >
                  {index + 1}
                </CustomStepLabel>
              </Step>
            ))}
          </CustomStepper>
        
          <Box mt={2}>
            <StepComponent formData={formData} handleChange={handleChange} errors={errors} />

            <Box mt={2} sx={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "0 20px",
              gap: "20px"
            }}>
              <Button disabled={currentStep === 0} onClick={handleBack} sx={{
                backgroundColor: currentStep === 0 ? "inherit" : "#260078",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#260078",
                },
              }}>
                Back
              </Button>
              <Button
                onClick={handleNext}
                disabled={!isStepComplete(currentStep)}
                sx={{
                  backgroundColor: "#260078",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#260078",
                  },
                }}
              >
                {currentStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </Box>
          </Box>
        </Paper>
      </Container>
    </>
  );
};

export default MultiStepForm;
