import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Grid, TextField, Button, FormControlLabel, Checkbox, Radio, RadioGroup, FormControl, FormLabel } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { baseUrl } from '../utils/baseUrl';
import { updateMfOrder } from '../global/store';
import './pages.style.css';

const BuyNow = ({ mfProductId, selectedMfProductData }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const orderData = useSelector((state) => state.mutualFund);
    const [paymentMethod, setPaymentMethod] = useState('UPI');
    const [initialAmount, setInitialAmount] = useState(orderData.mutualFundCardItemDetailDTOList[0]?.amount || '');
    const [mandateChanneltypes, setMandateChanneltypes] = useState([]);
    const [termsAccepted, setTermsAccepted] = useState(false);

    const { mfProductName, mfCategory, mfSubCategory, aumInCr, expenseRatio, dividendPlan, dividendtype } = selectedMfProductData;

    const fetchMandateChanneltypes = async () => {
        try {
            const response = await fetch(`${baseUrl}/config/paymentmodes`);
            const data = await response.json();
            setMandateChanneltypes(data);
        } catch (error) {
            console.error('Error fetching mandate channel types:', error);
        }
    };

    const registerOrderInSystem = async () => {
        const uniqueClientCode = JSON.parse(window.localStorage.getItem("userInfo")).clientCode;
        if (!uniqueClientCode) {
            navigate("/login");
            return;
        }

        const orderPayload = {
            uniqueClientCode: uniqueClientCode,
            mutualFundCardItemDetailDTOList: [{
                amount: Number(initialAmount),
                orderType: "LUMPSUM",
                mfProductId: mfProductId,
            }],
            paymentMode: paymentMethod,
        };

        try {
            const response = await fetch(`${baseUrl}/trxn/registerOrder`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(orderPayload)
            });

            const data = await response.json();
            console.log("Printing data while registering order.."+ data);
            if(data.status == true){
                const submitOrderPayLoad = {
                    uniqueClientCode: uniqueClientCode,
                    mfOrderID: data.orderID,
                };
                const buyOrderResponse = await fetch(`${baseUrl}/trxn/buyOrder`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(submitOrderPayLoad)
                });
                const buyOrderData = await buyOrderResponse.json();
                console.log("Printing data while buying order.."+ buyOrderData);
                if(buyOrderData.status == true){
                    console.log("Printing payment link..."+ buyOrderData.paymentLink);
                    window.open(buyOrderData.paymentLink, '_blank');
                    //alert("Please complete the payment.")
                    navigate("/mutual-funds/orders");
                } else{
                    console.error("Printing buyORderData response.. fail.. "+ buyOrderData.message);
                }
            } else{
                console.error("Exception while pushing order...");
                //alert("There was an exception while pushing mf order. We are working on it. Please try after some time.");
                navigate("/mutual-funds/orders");
            }
        } catch (error) {
            console.error("Error while pushing order to ")
            alert("Error", error.message);
            navigate("/mutual-funds/orders");
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case "paymentMethod":
                setPaymentMethod(value);
                break;
            case "investmentAmount":
                setInitialAmount(value);
                break;
            default:
                break;
        }
        dispatch(updateMfOrder({ [name]: value }));
    };

    const handleTermsChange = (e) => {
        setTermsAccepted(e.target.checked);
    };

    const handleSubmit = () => {
        if (!termsAccepted) {
            alert("Please accept the terms and conditions.");
            return;
        }
        registerOrderInSystem();
    };

    useEffect(() => {
        fetchMandateChanneltypes();
    }, []);

    return (
        <div className='manage-content-area'>
            <Box sx={{ height: '90vh', display: "flex", justifyContent: "center" }}>
                <Box sx={{ width: "75%" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography sx={{ display: "flex", gap: "10px" }}>
                                <img src='/assets/logo/Mutual_Fund_info.png' alt="Mutual Fund Info" width={25} height={25} />
                                {mfProductName}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ display: "flex" }}>
                            {mfCategory} {mfSubCategory} {aumInCr} {expenseRatio} {dividendPlan} {dividendtype}
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Payment Method</FormLabel>
                                <RadioGroup
                                    aria-label="payment method"
                                    name="paymentMethod"
                                    value={paymentMethod}
                                    onChange={handleChange}
                                    sx={{display:"flex",flexDirection:"row"}}
                                >
                                    {mandateChanneltypes && mandateChanneltypes.map((chanel, index) => (
                                        <FormControlLabel key={index} value={chanel} control={<Radio />} label={chanel} />
                                    ))}
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="investmentAmount"
                                label="Investment Amount"
                                value={initialAmount}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={termsAccepted}
                                        onChange={handleTermsChange}
                                        name="termsAccepted"
                                    />
                                }
                                label="I accept the terms and conditions"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                fullWidth
                            >
                                Buy Now
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </div>
    );
};

export default BuyNow;
