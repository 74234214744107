const getPLColor = (plPercent) => {
    return plPercent.startsWith('+') ? 'green' : 'red';
};

const isClientCodeValid=()=>{
        const userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
        const clientCode =userInfo?.clientCode;
        const loginTimestamp =userInfo?.loginTimestamp;
        if (!clientCode || !loginTimestamp) return false;
      
        const currentTime = Date.now();
        const oneDaysInMilliseconds = 1 * 24 * 60 * 60 * 1000;
      
        return (currentTime - loginTimestamp) < oneDaysInMilliseconds;      
};

const isUserRegistered=()=>{
    const userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
    const userRegistered = userInfo?.isInvestorRegistered;
    if(userRegistered === true){
        return true;
    } 
    return false;
};

const isUserRegisteredAsDistributor=()=>{
    const userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
    const userRegisteredAsDistributor = userInfo?.isDistributorRegistered;
    if(userRegisteredAsDistributor === true){
        return true;
    }
    return false;
};

module.exports={
    getPLColor,
    isClientCodeValid,
    isUserRegistered,
    isUserRegisteredAsDistributor
}