import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Grid, TextField, Button, FormControlLabel, Checkbox, InputLabel, Select, MenuItem, FormControl } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { baseUrl } from '../utils/baseUrl';
import { updateMfOrder } from '../global/store';
import './pages.style.css';

const RegisterOrder = ({mfProductId,selectedMfProductData}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const orderData = useSelector((state) => state.mutualFund);
    const [installmentAmount, setInstallmentAmount] = useState(orderData.mutualFundCardItemDetailDTOList[0].amount || '');
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [mandateName, setMandateName] = useState('');
    const [frequency, setFrequency] = useState(orderData.mutualFundCardItemDetailDTOList[0].sipInfo.frequency || 'Monthly');
    const [sipStartDay, setSipStartDay] = useState(orderData.mutualFundCardItemDetailDTOList[0].sipInfo.sipStartDay || '1');
    const [mandateChanneltypes, setMandateChanneltypes] = useState([]);

    const {mfProductName,mfCategory,mfSubCategory,aum,expenseRatio,dividendPlan,dividendtype}=selectedMfProductData

    const handleChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case "mandate-name":
                setMandateName(value);
                break;
            case "frequency":
                setFrequency(value);
                break;
            case "sipStartDay":
                setSipStartDay(value);
                break;
            case "installmentAmount":
                setInstallmentAmount(value);
                break;
            default:
                break;
        }
    };

    const fetchMandateChanneltypes = async () => {
        try {
            const response = await fetch(`${baseUrl}/config/mandateChanneltypes`);
            const data = await response.json();
            setMandateChanneltypes(data);
        } catch (error) {
            console.error('Error fetching mandate channel types:', error);
        }
    };

    const registerOrderInSystem = async () => {
        const uniqueClientCode = JSON.parse(window.localStorage.getItem("userInfo")).clientCode;
        if (!uniqueClientCode) {
            navigate("/login");
            return;
        }

        const orderPayload = {
            ...orderData,
            uniqueClientCode: uniqueClientCode,
            mutualFundCardItemDetailDTOList: [{
                ...orderData.mutualFundCardItemDetailDTOList[0],
                amount: Number(installmentAmount),
                orderType:"SIP",
                mfProductId:mfProductId,
                sipInfo: {
                    ...orderData.mutualFundCardItemDetailDTOList[0].sipInfo,
                    amount: Number(installmentAmount),
                    channelType:mandateName,
                    frequency: frequency,
                    sipStartDay: sipStartDay
                }
            }],
            paymentMode: mandateName,
        };

        try {
            const response = await fetch(`${baseUrl}/trxn/registerOrder`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(orderPayload)
            });

            const data = await response.json();
            if (!response.status === 202) {
                const errorMsg = data.message;
                throw new Error(errorMsg);
            }

        } catch (error) {
            alert("Error", error.message);
        }
    };

    const handleTermsChange = (e) => {
        setTermsAccepted(e.target.checked);
    };

    const handleSubmit = () => {
        if (!termsAccepted) {
            alert("Please accept the terms and conditions.");
            return;
        }
        dispatch(updateMfOrder({
            mfProductId: orderData.mutualFundCardItemDetailDTOList[0].mfProductId,
            orderType: orderData.mutualFundCardItemDetailDTOList[0].orderType,
            amount: installmentAmount,
            sipInfo: {
                frequency,
                sipStartDay
            },
            mandateName,
        }));
        registerOrderInSystem();
    };

    useEffect(() => {
        fetchMandateChanneltypes();
    }, []);

    return (
        <div className='manage-content-area'>
            <Box sx={{ height: '90vh', display: "flex", justifyContent: "center" }}>
                <Box sx={{ width: "75%" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography sx={{ display: "flex", gap: "10px" }}>
                                <img src='/assets/logo/Mutual_Fund_info.png' alt="Mutual Fund Info" width={25} height={25} />
                                {selectedMfProductData.mfProductName}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}  sx={{display:"flex"}}>
                           {mfCategory} {mfSubCategory} {aum} {expenseRatio} {dividendPlan} {dividendtype} 
                        </Grid>
                       
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="mandate-chanel-type">Mandate Name</InputLabel>
                                <Select
                                    labelId="mandate-chanel-type"
                                    id="mandate-chanel-type"
                                    name="mandate-name"
                                    value={mandateName}
                                    onChange={handleChange}
                                    label="Mandate Name"
                                >
                                    {mandateChanneltypes && mandateChanneltypes.map((option, index) => (
                                        <MenuItem key={index} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                           <p> <Link to="/mandate"> Create mandate</Link></p>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="frequency-label">Frequency</InputLabel>
                                <Select
                                    labelId="frequency-label"
                                    id="frequency"
                                    name="frequency"
                                    value={frequency}
                                    onChange={handleChange}
                                    label="Frequency"
                                >
                                    <MenuItem value="Monthly">Monthly</MenuItem>
                                    <MenuItem value="Yearly">Yearly</MenuItem>
                                    <MenuItem value="Weekly">Weekly</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="sip-start-day-label">SIP Start Date</InputLabel>
                                <Select
                                    labelId="sip-start-day-label"
                                    id="sipStartDay"
                                    name="sipStartDay"
                                    value={sipStartDay}
                                    onChange={handleChange}
                                    label="SIP Start Date"
                                >
                                    {[...Array(31)].map((_, index) => (
                                        <MenuItem key={index} value={index + 1}>
                                            {index + 1}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="installmentAmount"
                                label="Installment Amount"
                                value={installmentAmount}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={termsAccepted}
                                        onChange={handleTermsChange}
                                        name="termsAccepted"
                                    />
                                }
                                label="I accept the terms and conditions"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                fullWidth
                            >
                                Create SIP Order
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </div>
    );
};

export default RegisterOrder;
