import React from 'react'
import Searchbar from '../Layout/SearchBar'
import RegistrationDetails from '../components/multiStepForm/RegistrationDetails'
import { useLocation } from 'react-router-dom';

const RegistartionOverView = () => {
    const location = useLocation();
    const { mfInvestorInfo } = location.state;
  return (
    <div>
      <Searchbar/>
      <RegistrationDetails mfInvestorInfo={mfInvestorInfo}/>
    </div>
  )
}

export default RegistartionOverView
