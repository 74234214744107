import React, { createContext, useState, useContext } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const user = JSON.parse(window.localStorage.getItem("userInfo"));
  const clientCode = user?.clientCode;
  const loginTimestamp = user?.loginTimestamp;
  // console.log("loginTimestamp",loginTimestamp,clientCode)
  const [userInfo, setUserInfo] = useState(user || null);

  return (
    <UserContext.Provider value={{ userInfo, setUserInfo }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};
