import React, { createContext, useState, useContext, useCallback } from 'react';

const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
  const [openSections, setOpenSections] = useState({
    mutualFunds: false,
    amcs: false,
    productCategories: false,
    productSubCategories: false,
    dividendType: false,
    expenseRatio: false,
    dividendPlan: false,
    aum: false,
    minPurchaseAmount: false,   
    riskLevels: false, 
    returnLevels: false,
  });

  const [selectedFilters, setSelectedFilters] = useState({
    amcs: [],
    productCategories: [],
    productSubCategories: [],
    dividendType: [],
    expenseRatio: [],
    dividendPlans: [],
    aum: [],
    minPurchaseAmount: [],
    riskLevels: [],
    returnLevels: []
  });

  const removeFilter = useCallback((filterKey, filterValue) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [filterKey]: prevFilters[filterKey].filter((value) => value !== filterValue),
    }));
  }, []);

  const handleSectionToggle = useCallback((section) => {
    setOpenSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  }, []);

  const handleFilterToggle = useCallback((filterKey, value) => {
    console.log("filterKey",filterKey,value)
    setSelectedFilters((prevState) => ({
      ...prevState,
      [filterKey]: prevState[filterKey].includes(value)
        ? prevState[filterKey].filter((item) => item !== value)
        : [...prevState[filterKey], value],
    }));
  }, []);

  return (
    <SidebarContext.Provider
      value={{
        openSections,
        selectedFilters,
        removeFilter,
        handleSectionToggle,
        handleFilterToggle,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebar = () => useContext(SidebarContext);
