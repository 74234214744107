import './App.css';
import { Routes, Route, Navigate } from "react-router-dom";
import Home from './Pages/Home';
import Debt from './Pages/Debt';
import Equity from './Pages/Equity';
import Holding from './Pages/Holding';
import Layout from './Layout/MainLayout';
import Sips from './Pages/SIPs';
import Orders from './Pages/Orders';
import Explore from './Pages/Explore';
import SWPs from './Pages/SWPs';
import MutualFundInfo from './Pages/MutualFundInfo';
import WatchlistPage from './Pages/WatchListPage';
import Registration from './Pages/Registration';
import RegistartionOverView from './Pages/RegistartionOverView';
import Login from './Pages/Login';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { UserProvider } from './Context/UserContext';
import Mandate from './Pages/Mandate';
import { isClientCodeValid } from './utils/function';
import { isUserRegistered } from './utils/function';
import { isUserRegisteredAsDistributor } from './utils/function';
import ResetPassword from './Pages/ResetPassword';
import DistributorLayOut from './Layout/DistributorLayout';
import RegisterOrder from './Pages/RegisterOrder';
import BuyNow from './Pages/BuyOrder';
import DashboardLayout from './Layout/DashboardLayout';
import DistributorDetails from './Pages/DistributorDetails';
import DistributorClientDetails from './Pages/DistributorClientDetails';
import AddDistributorDetails from './Pages/AddDistributorDetails';
import AddClients from './Pages/AddClients';
import ProfileCard from './Pages/ProfileView';
import AboutUs from './Pages/AboutUs';
import TermsAndConditions from './Pages/TermsAndConditions';
import ComingSoon from './Pages/CommingSoon';

const PrivateRoute = ({ element: Element, ...rest }) => {
  return (
    isUserRegistered() ? (
      <Element {...rest} />
    ) : (
      <Navigate to="/registration" />
    )
  );
};

const PrivateDistributorRoute = ({ element: Element }) => {
  return (
    isUserRegisteredAsDistributor() ? (
      <Element />
    ) : (
      <Navigate to="/distributor/add/details" />
    )
  );
};

function App() {
  return (
    <div className="App">
      <UserProvider>
        <GoogleOAuthProvider clientId={"724941567456-hl9aurii79cmoagpmr2mmc97ad5flk6c.apps.googleusercontent.com"}>
          <Routes>
            <Route path="dashboard" element={<DashboardLayout />} >
              {/* <Route path="summary" element={<Holding />} /> */}
              <Route path="summary" element={<ComingSoon />} />
              {/* <Route path="profile" element={<ProfileCard />} /> */}
              <Route path="profile" element={<PrivateRoute element={ProfileCard} />} />
              {/* <Route path="watchlist" element={<Holding />} /> */}
            </Route>
            {/* <Route path="/administration" element={<ComingSoon />} /> */}
            <Route path="/" element={< Login />} />
            <Route path="/reset-passowrd" element={<ResetPassword />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="/mf/product-info/:mfProductId" element={<MutualFundInfo />} />
            <Route path="/watchlist" element={<WatchlistPage />} />
            <Route path="/mandate" element={<PrivateRoute element={Mandate} />} />
            <Route path="/login" element={<Login />} />
            <Route path="/registration" element={<Registration />} />
            <Route path="/registration-overview" element={<RegistartionOverView />} />
            <Route path="mutual-funds" element={<Layout />}>
              {/* <Route path="sips" element={<Sips />} /> */}
              <Route path="sips" element={<ComingSoon />} />
              <Route path="orders" element={<Orders />} />
              <Route path="explore" element={<Explore />} />
              {/* <Route path="holdings" element={<Holding />} /> */}
              <Route path="holdings" element={<ComingSoon />} />
              {/* <Route path="swp" element={<SWPs />} /> */}
              <Route path="swp" element={<ComingSoon />} />
              <Route path="register/order" element={<RegisterOrder />} />
              <Route path="buy-now/order" element={<BuyNow />} />
            </Route>
            <Route path="distributor" element={<DistributorLayOut />} >
              <Route path="clients" element={<DistributorClientDetails />} />
              {/* <Route path="details" element={<DistributorDetails />} /> */}
              <Route path="details" element={<PrivateDistributorRoute element={DistributorDetails} />} />
              <Route path="make/trxn" element={<div>Make Transaction</div>} />
              <Route path="generate/reports" element={<div>Make Transaction</div>} />
              <Route path="add/details" element={<AddDistributorDetails />} />
              <Route path="add/clients" element={<AddClients />} />
            </Route>
            <Route path="/category/debt" element={<Debt />} />
            <Route path="/category/equity" element={<Equity />} />
            <Route path="/category/hybrid" element={<Holding />} />
          </Routes>
        </GoogleOAuthProvider>
      </UserProvider>
    </div>
  );
}

export default App;
