import React from 'react';
import '../App.css';
import {
    TextField, Box, IconButton,Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import NotificationsIcon from '@mui/icons-material/Notifications';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import SearchIcon from '@mui/icons-material/Search';

const StyledSearchbar = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: "space-between",
    height: 45,
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    padding: "30px",
    backgroundColor: "#260078",
    color: "#fff"
});

const StyledLink = styled(Link)({
    color: '#fff',
    textDecoration: 'none',
    textWrap: 'nowrap',
    '&:hover': {
      textDecoration: 'underline',
    }
  });

  

function TermsAndConditions() {
    const navigate = useNavigate();
    const handleWatchlistClick = () => {
        navigate('/watchlist');
    };
  return (
    <>
    <section>
                <StyledSearchbar>
                    <Box>
                        <Typography sx={{ color: "#AEADD6", }}>
                            <img src="/assets/logo/niveshiq.svg" width={100}/>
                        </Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "row", width: "90%", gap: "15px", }}>
                        <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                            {/* <StyledLink to="/">Home</StyledLink> */}
                            {/* <StyledLink to="/mutual-funds">Mutual Funds</StyledLink> */}
                            {/* <StyledLink to="/sip-calculator">SIP Calculator</StyledLink> */}
                            <StyledLink to="/">Login</StyledLink>
                        </Box>
                        {/* <TextField
                            fullWidth
                            variant="standard"
                            placeholder="Search..."
                            sx={{
                                border: "1px solid #ccc",
                                padding: "2px",
                                borderRadius: "20px",
                                '& .MuiInput-root::before': {
                                    borderBottom: 'none !important',
                                },
                                backgroundColor: "#fff",
                                '& .MuiInputBase-root': {
                                  borderBottom: 'none !important',
                                },
                                '& .MuiInputBase-root:before': {
                                  borderBottom: 'none !important', 
                                },
                                '& .MuiInputBase-root:after': {
                                  borderBottom: 'none !important', 
                                },
                                '& .MuiInputBase-root:hover:before': {
                                  borderBottom: 'none !important', 
                                }
                            }}
                            InputProps={{
                                startAdornment: (
                                    <IconButton size="small">
                                        <SearchIcon />
                                    </IconButton>
                                ),
                            }}
                        /> */}
                        {/* <Box sx={{ display: "flex", color: "blue" }}>
                            <IconButton size="small" sx={{ color: "#1976d2" }}
                                onClick={handleWatchlistClick}>
                                <FavoriteBorderIcon />
                                Watchlist
                            </IconButton>
                            <IconButton size="small" sx={{ color: "#1976d2" }}>
                                <NotificationsIcon />
                            </IconButton>
                        </Box> */}
                    </Box>
                </StyledSearchbar>
            </section>
    <div className="aboutUs">
      <h1>Terms and Conditions.</h1>
      <br></br>
      <h2>Effective Date: 1st September 2024</h2>
      <br></br>
      <p>Welcome to <b>NiveshIq.in</b>, ("Company", "we", "our", "us"). By accessing or using our website <a href="/">Niveshiq.in</a> (the "Site") and our services, you agree to comply with and be bound by the following Terms and Conditions (the "Terms"). Please read these Terms carefully before using our services.</p>
      <br/>
      <h2>Acceptance of Terms</h2>
      <br/>
      <ul style={{paddingLeft:'25px'}}>
        <li>By using the Site and our services, you agree to these Terms and our Privacy Policy. If you do not agree with any part of these Terms, you must not use our Site or services.</li>
      </ul>
      <br/>
      <h2>Eligibility</h2>
      <br/>
      <h3>To use our services, you must:</h3>
      <br/>
      <ul style={{paddingLeft:'25px'}}>
        <li>Be at least 18 years old.</li>
        <li>Be a resident of India.</li>
        <li>Have the legal capacity to enter into binding contracts.</li>
      </ul>
      <br/>
      <h2>Services Offered</h2>
      <br/>
      <h3>Our Site provides a platform for users to:</h3>
      <br/>
      <ul style={{paddingLeft:'25px'}}>
        <li>Research and invest in mutual funds.</li>
        <li>Access fund information, performance data, and investment options.</li>
        <li>Receive recommendations and financial insights.</li>
      </ul>
      <br/>
      <h2>Regulatory Compliance</h2>
      <br/>
      <h3>We are a registered mutual fund distributor authorized by the Securities and Exchange Board of India (SEBI). Our services comply with SEBI regulations and guidelines, including:</h3>
      <br/>
      <ul style={{paddingLeft:'25px'}}>
        <li><b>SEBI (Mutual Funds) Regulations, 1996</b></li>
        <li><b>SEBI (Investment Advisers) Regulations, 2013</b></li>
        <li><b>SEBI (Portfolio Managers) Regulations, 1993</b></li>
      </ul>
      <br/>
      <h2>User Accounts</h2>
      <br/>
      <ul style={{paddingLeft:'25px'}}>
        <li><b>Account Creation:</b> You must create an account to access certain features of the Site. You agree to provide accurate, complete, and current information during the registration process and to update this information as necessary.</li>
        <li><b>Security:</b> You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</li>
        <li><b>Account Termination:</b> We reserve the right to terminate or suspend your account if you breach these Terms or for any other reason we deem appropriate.</li>
      </ul>
      <br/>
      <h2>Investment Risks</h2>
      <br/>
      <h3>Investing in mutual funds involves risks, including but not limited to:</h3>
      <br/>
      <ul style={{paddingLeft:'25px'}}>
        <li><b>Market Risk:</b> The value of investments may fluctuate due to market conditions.</li>
        <li><b>Credit Risk:</b> The risk that issuers of securities may default.</li>
        <li><b>Liquidity Risk:</b> The risk of not being able to sell investments quickly.</li>
      </ul>
      <br></br>
      <h4>You should carefully read the scheme-related documents (SID) and consult with a financial advisor before making any investment decisions.</h4>
      <br/>
      <h2>Fees and Charges</h2>
      <br/>
      <h3>We may receive commissions or fees from mutual fund companies. Any fees or charges associated with mutual fund investments will be disclosed as per SEBI regulations.</h3>
      <br/>
      <h2>Content and Intellectual Property</h2>
      <br/>
      <ul style={{paddingLeft:'25px'}}>
        <li><b>Content:</b> All content on the Site, including text, graphics, and software, is the property of <a href="/">niveshiq.in</a> or its licensors and is protected by intellectual property laws.</li>
        <li><b>License:</b> We grant you a limited, non-exclusive, non-transferable license to access and use the Site for personal, non-commercial purposes.</li>
      </ul>
      <br/>
      <h2>Disclaimers</h2>
      <br></br>
      <ul style={{paddingLeft:'25px'}}>
        <li><b>No Warranty:</b> The Site and our services are provided "as is" and "as available" without any warranties of any kind, either express or implied.</li>
        <li><b>Accuracy:</b> We strive to provide accurate and timely information, but we do not guarantee the accuracy, completeness, or reliability of any information provided on the Site.</li>
      </ul>
      <br/>
      <h2>Limitation of Liability</h2>
      <br/>
      <p>To the fullest extent permitted by law, <a href="/">niveshiq.in</a> and its affiliates shall not be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with your use of the Site or services.</p>
      <br/>
      <h2> Indemnification </h2>
      <br/>
      <p>You agree to indemnify, defend, and hold harmless <a href='/'>niveshiq.in</a>, its affiliates, and their respective officers, directors, employees, and agents from any claims, liabilities, damages, losses, or expenses arising out of your use of the Site or violation of these Terms.</p>
      <br/>
      <h2>Changes to Terms</h2>
      <br/>
      <p>We reserve the right to modify these Terms at any time. We will notify you of any material changes by posting the updated Terms on the Site. Your continued use of the Site after any changes indicates your acceptance of the new Terms.</p>
      <br/>
      <h2>Governing Law</h2>
      <br/>
      <p>These Terms shall be governed by and construed in accordance with the laws of India. Any disputes arising under or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts in Bangalore, India.</p>
      <br/>
      <h2>Contact Information</h2>
      <br/>
      <p>If you have any questions about these Terms, please contact us at:</p>
      <br/>
      <ul style={{paddingLeft:'25px'}}>
        <li><b>Email:</b> <mark>no-reply@niveshiq.in</mark></li>
      </ul>
    </div>
    </>
    
  );
}

export default TermsAndConditions;