import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Grid, TextField, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { baseUrl } from '../utils/baseUrl';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import './pages.style.css';
import dayjs from 'dayjs';

const AddDistributorDetails = () => {
    const navigate = useNavigate();

    const initialFormState = {
        arnNumber: "",
        panNumber: "",
        validFrom:"",
        validTo:""
    }
    
    // const user = JSON.parse(window.localStorage.getItem("userInfo"));
    
    // const[userName,setUserName] = useState("Hello Distributor");
    // if(user){
    //     //userName = "Hello " + user.name;
    //     setUserName("Hello "+user.name);
    // } else {
    //     setUserName("Hello Distributor")
    // }
    //const userName = "Hello " + user?.userName || "Distributor";
    const dispatch = useDispatch();
    const [formData, setFormData] = useState(initialFormState);
    const [loading, setLoading] = useState(false)

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    }

    const handleAlertMessage = (message) => {
        alert(message);
    }

    const handleDateChange = (date) => {
        const convertedTz = convertTZ(date, "Asia/Kolkata");
        const requiredDate = toShortFormat(convertedTz);
        formData.validFrom = requiredDate;
      };

      const handleValidToDateChangeChange = (date) => {
        const convertedTz = convertTZ(date, "Asia/Kolkata");
        const requiredDate = toShortFormat(convertedTz);
        formData.validTo = requiredDate;
      };

      function convertTZ(date, tzString) {
        return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString }));
      }
    
      function toShortFormat(date) {
        const monthNames = ["Jan", "Feb", "Mar", "Apr",
          "May", "Jun", "Jul", "Aug",
          "Sep", "Oct", "Nov", "Dec"];
    
        const day = date.getDate();
        if(day<10){
          day = "0"+day;
        }
        
        const monthIndex = date.getMonth();
        const monthName = monthNames[monthIndex];
    
        const year = date.getFullYear();
    
        return `${day}-${monthName}-${year}`;
      }

    const handleSubmit = async (e) => {
        const uniqueClientCode = JSON.parse(window.localStorage.getItem("userInfo")).clientCode;
        e.preventDefault();
        const payload = {
            uniqueClientCode: uniqueClientCode,
            arnNumber: formData.arnNumber,
            panNumber:formData.panNumber,
            validFrom:formData.validFrom,
            validTo:formData.validTo,
        }
        try {
            setLoading(true);
            const response = await fetch(`${baseUrl}/distributor/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload)
            });

            const data = await response.json();
            console.log("Save Distributor Details", data);
            if(data.status == true){
                handleAlertMessage(data.message);
                setLoading(false);
                handleReset();
            } else {
                handleAlertMessage(data.message);
                setLoading(false);
            }    
        } catch (error) {
            setLoading(false);
            alert(error.message);
            //throw new Error("Something went wrong")
        }
    };

    const handleReset = (e) => {
        const formData = {
            arnNumber : '',
            panNumber : '',
            validFrom : '',
            validTo : '',
        }
        setFormData(formData);
    };


    return ( 
        <div className='manage-content-area'>
            <Box sx={{ height: '60vh', display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Box sx={{ width: "50%" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography>Hello</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                name="arnNumber"
                                label="ARN-Number"
                                value={formData.arnNumber}
                                onChange={handleInputChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                name="panNumber"
                                label="PAN Number"
                                value={formData.panNumber}
                                onChange={handleInputChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                             <LocalizationProvider dateAdapter={AdapterDayjs} sx={{width:"100%"}}>
                             <DatePicker
                                label="Valid From"
                                value={dayjs(formData.validFrom)}
                                onChange={handleDateChange}
                                sx={{width:"100%"}}
                                renderInput={(params) => (
                             <TextField
                                {...params}
                                fullWidth
                                InputProps={{
                                    sx: { borderRadius: '15px' },
                                }}
                            />)}
                            required
                            />
                            </LocalizationProvider>
                            </Grid>

                            <Grid item xs={6}>
                             <LocalizationProvider dateAdapter={AdapterDayjs} sx={{width:"100%"}}>
                             <DatePicker
                                label="Valid Upto"
                                value={dayjs(formData.validTo)}
                                onChange={handleValidToDateChangeChange}
                                sx={{width:"100%"}}
                                renderInput={(params) => (
                             <TextField
                                {...params}
                                fullWidth
                                InputProps={{
                                    sx: { borderRadius: '15px' },
                                }}
                            />)}
                            required
                            />
                            </LocalizationProvider>
                            </Grid>
                        
                        <Grid item xs={3}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                fullWidth
                            >
                                Submit
                            </Button>
                        </Grid>
                        <Grid item xs={3}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleReset}
                                fullWidth
                            >
                            Reset
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </div>
    );
};

export default AddDistributorDetails;
