import React, { useState, useEffect } from 'react';
import { TextField, Grid, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { baseUrl } from '../../utils/baseUrl';


const FatcaInformation = ({ formData, handleChange }) => {
  //const { addrType, politicallyExposedPerson, incomeSlab, sourceWealth } = formData.fatcaDetails;
  const { politicallyExposedPerson, incomeSlab, sourceWealth } = formData.fatcaDetails;
  const { taxStatus, gender, occupation, holdingNature} = formData;
  const [addressypeList, setAddressTypeList] = useState([]);
  const [politicallyExposedPersonList, setPoliticallyExposedPersonList] = useState([]);
  const [incomeSlabList, setIncomeSlabList] = useState([]);
  const [sourceOfWealthList, setSourceOfWealthList] = useState([]);
  const [taxStatusList, setTaxStatusList] = useState([]);
  const [occupationList, setOccupationList] = useState([]);

  const fetchAddressTypeOptions = async () => {
    try {
      const response = await fetch(`${baseUrl}/config/addressTypes`);
      const data = await response.json();
      setAddressTypeList(data);
    } catch (error) {
      console.error('Error fetching mobile relation options:', error);
    }
  };

  const fetchPoliticallyExposedPersonTypeOptions = async () => {
    try {
      const response = await fetch(`${baseUrl}/config/politicallyexposedstatus`);
      const data = await response.json();
      setPoliticallyExposedPersonList(data);
    } catch (error) {
      console.error('Error fetching mobile relation options:', error);
    }
  };
  const fetchIncomeSlabOptions = async () => {
    try {
      const response = await fetch(`${baseUrl}/config/incomeDetailsList`);
      const data = await response.json();
      setIncomeSlabList(data);
    } catch (error) {
      console.error('Error fetching mobile relation options:', error);
    }
  };

  const fetchSourceOfWealthOptions = async () => {
    try {
      const response = await fetch(`${baseUrl}/config/wealthsourcelist`);
      const data = await response.json();
      setSourceOfWealthList(data);
    } catch (error) {
      console.error('Error fetching mobile relation options:', error);
    }
  };

  const fetchTaxStatusListOptions = async () => {
    try {
        const response = await fetch(`${baseUrl}/config/taxDetailsList`);
        const data = await response.json();
        setTaxStatusList(data);
    } catch (error) {
        console.error('Error fetching mobile relation options:', error);
    }
};

const fetchOccupationListOptions = async () => {
    try {
        const response = await fetch(`${baseUrl}/config/occupationList`);
        const data = await response.json();
        setOccupationList(data);
    } catch (error) {
        console.error('Error fetching mobile relation options:', error);
    }
};

  useEffect(() => {
    fetchAddressTypeOptions();
    fetchPoliticallyExposedPersonTypeOptions();
    fetchIncomeSlabOptions();
    fetchSourceOfWealthOptions();
    fetchTaxStatusListOptions();
    fetchOccupationListOptions();
  }, []);
  return (
    <section style={{ width: '85%', margin: '40px auto' }}>
    <Grid container spacing={2}>
      {/* <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="addrType-type">Address Type</InputLabel>
          <Select
            labelId="addrType-type"
            id="addrType-type"
            name="fatcaDetails.addrType"
            value={addrType}
            onChange={handleChange}
            label="Address Type"
            sx={{ borderRadius: '15px' }}
          >
            {addressypeList && addressypeList.map((option, index) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid> */}
      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <InputLabel id="politicallyExposedPerson-type">Politically Exposed Person</InputLabel>
          <Select
            labelId="politicallyExposedPerson-type"
            id="politicallyExposedPerson-type"
            name="fatcaDetails.politicallyExposedPerson"
            value={politicallyExposedPerson}
            onChange={handleChange}
            label="Politically Exposed Person"
            sx={{ borderRadius: '15px' }}
          >
            {politicallyExposedPersonList && politicallyExposedPersonList.map((option, index) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <InputLabel id="incomeSlab-type">Income Slab</InputLabel>
          <Select
            labelId="incomeSlab-type"
            id="incomeSlab-type"
            name="fatcaDetails.incomeSlab"
            value={incomeSlab}
            onChange={handleChange}
            label="Income Slab"
            sx={{ borderRadius: '15px' }}
          >
            {incomeSlabList && incomeSlabList.map((option, index) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <InputLabel id="sourceWealth-type">Source of Wealth</InputLabel>
          <Select
            labelId="psourceWealth-type"
            id="sourceWealth-type"
            name="fatcaDetails.sourceWealth"
            value={sourceWealth}
            onChange={handleChange}
            label="Source of Wealth"
            sx={{ borderRadius: '15px' }}
          >
            {sourceOfWealthList && sourceOfWealthList.map((option, index) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <InputLabel id="taxStatus-type">TaxStatus Type</InputLabel>
                        <Select
                            labelId="taxStatus-type"
                            id="taxStatus-type"
                            name="taxStatus"
                            value={taxStatus}
                            onChange={handleChange}
                            label="TaxStatus Type"
                            sx={{ borderRadius: '15px' }}

                        >
                            {taxStatusList && taxStatusList.map((option, index) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <InputLabel id="occupation-type">Occupations</InputLabel>
                        <Select
                            labelId="occupation-type"
                            id="occupation-type"
                            name="occupation"
                            value={occupation}
                            onChange={handleChange}
                            label="Occupation"
                            sx={{ borderRadius: '15px' }}

                        >
                            {occupationList && occupationList.map((option, index) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>               
                
    </Grid>
    </section>
  );
};

export default FatcaInformation;
