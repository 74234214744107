import React, { useEffect, useState } from 'react';
import { baseUrl } from '../utils/baseUrl';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Button } from '@mui/material';
import { useSidebar } from '../Context/SidebarContext';
import { getPLColor } from '../utils/function';
import './pages.style.css'


const Orders = () => {
  const { selectedFilters,removeFilter } = useSidebar();
  const [orders, setOrders] = useState([]);
  const [numberOfResult, setNumberOfResult] = useState([]);
  const [numberOFPages, setNumberOFPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const handleAddToWatchList = (productId) => {
    console.log("product Id to add watchlist", productId)
  }

  const nextPage = () => {
    if (currentPage < numberOFPages) {
        setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    const getMutualFunds = async () => {

      try {
          
          const uniqueClientCode=JSON.parse(window.localStorage.getItem("userInfo"))?.clientCode || "";
          console.log("Printing uniqueclient code..");
          const response = await fetch(`${baseUrl}/trxn/orders?uniqueClientCode=${uniqueClientCode}&page=${currentPage}&size=15`);

          if (!response.ok) {
              throw new Error("An error occurred");
          }

          const data = await response.json();
          console.log("Printing data", data);
          setOrders(data.mfOrderItemDetailResponseList);
          setNumberOFPages(data.totalNumberOfPages);
          setNumberOfResult(data.totalNumberOfRecords);
          setCurrentPage(data.currentPage);
      } catch (error) {
          console.error("Error fetching mutual funds:", error);
      }
  };

    getMutualFunds();
  }, [currentPage]);

  console.log("orders", orders)
  return (

    <div className="funds">
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Fund Name</TableCell>
                                <TableCell>Placed on</TableCell>
                                <TableCell>Amount</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Remarks</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {orders.map((order, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                            {/* <img src={fund.logo} alt={fund.name} width={25} height={25} /> */}
                                            <div>
                                                <p className="fund-name">{order?.productName}</p>
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                            {/* <img src={fund.logo} alt={fund.name} width={25} height={25} /> */}
                                            <div>
                                                <p className="fund-create-date">{order?.createdAt}</p>
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                            {/* <img src={fund.logo} alt={fund.name} width={25} height={25} /> */}
                                            <div>
                                                <p className="fund-amount">{order?.amount}</p>
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                            {/* <img src={fund.logo} alt={fund.name} width={25} height={25} /> */}
                                            <div>
                                                <p className="fund-order-status">{order?.orderStatus}</p>
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                            {/* <img src={fund.logo} alt={fund.name} width={25} height={25} /> */}
                                            <div>
                                                <p className="fund-fail-remarks">{order?.failRemarks}</p>
                                            </div>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
                <Button variant="contained" disabled={currentPage === 1} onClick={prevPage}>
                    Previous
                </Button>
                <Typography variant="body1">Page {currentPage} of {numberOFPages}</Typography>
                <Button variant="contained" disabled={currentPage === numberOFPages} onClick={nextPage}>
                    Next
                </Button>
            </Box>
            </div>

    // <div>
    //   <Box p={2} sx={{
    //     display: "flex",
    //     flexDirection: "column",
    //     gap: "20px"
    //   }}>
    //     {mutualFund.map((item, index) => (
    //       <Paper key={index} sx={{ display: "flex", gap: "10px", padding: "10px 5px" }}>
    //         <Box>
    //           <img src="/assets/logo/mutual_fund.png" alt="mutual-fund" width={30} height={30} />
    //         </Box>
    //         <Box sx={{ width: "100%" }}>
    //           <h5>{item.mfProductName}</h5>
    //           <section className='mutual-fund-des'>
    //             <div className='desc-section'>
    //               <span>AUM ₹60,000.43 cr</span>
    //               <span>Growth</span>
    //               <span>Equity</span>
    //               <span>Mid Cap.</span>
    //             </div>
    //           </section>
    //         </Box>
    //       </Paper>
    //     ))}
    //   </Box>
    // </div>
  )
}

export default Orders
