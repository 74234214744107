import React, { useState } from 'react';
import { Box, Grid, TextField, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { baseUrl } from '../utils/baseUrl';
import './pages.style.css';

const AddClients = () => {
    const navigate = useNavigate();

    const initialFormState = {
        email: ""
    }
    const [formData, setFormData] = useState(initialFormState);
    const [loading, setLoading] = useState(false)

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    }

    const handleAlertMessage = (message) => {
        alert(message);
    }

    const handleSubmit = async (e) => {
        const uniqueClientCode = JSON.parse(window.localStorage.getItem("userInfo"))?.clientCode;
        if (!uniqueClientCode) {
            navigate("/login");
            return;
        }
        e.preventDefault();
        const payload = {
            uniqueClientCode: uniqueClientCode,
            email: formData.email
        }
        try {
            setLoading(true);
            const response = await fetch(`${baseUrl}/distributor/invite`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload)
            });

            const data = await response.json();
            console.log("Invite client response..", data);
            if(data.status == true){
                handleAlertMessage(data.message);
                setLoading(false);
                handleReset();
            } else {
                handleAlertMessage(data.message);
                setLoading(false);
            }    
        } catch (error) {
            setLoading(false);
            alert(error.message);
        }
    };

    const handleReset = (e) => {
        const formData = {
            email : ''
        }
        setFormData(formData);
    };


    return ( 
        <div className='manage-content-area'>
            <Box sx={{ height: '60vh', display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Box sx={{ width: "20%" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <p>An invitation link will be sent to the user.</p>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="email"
                                label="Client's e-mail"
                                value={formData.email}
                                onChange={handleInputChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                fullWidth
                            >
                                Submit
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleReset}
                                fullWidth
                            >
                            Reset
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </div>
    );
};

export default AddClients;
