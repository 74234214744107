import React from 'react'
import Searchbar from '../Layout/SearchBar'
import { useUser } from '../Context/UserContext';
import CreateMandate from '../components/mandate/CreateMandate';

const Mandate = () => {
  const { setUserInfo, userInfo } = useUser();
  const isUserLoggedIn = JSON.parse(window.localStorage.getItem("userInfo"))
  console.log("isUserLoggedIn", isUserLoggedIn)
  return (
    <div>
      <Searchbar />
      <CreateMandate />
    </div>
  )
}

export default Mandate
