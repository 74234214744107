export const getWatchList = () => {
  const watchList = localStorage.getItem('watchList');
  return watchList ? JSON.parse(watchList) : [];
};

export const addToWatchList = (mfProductId, mfProductName) => {
  const watchList = getWatchList();
  if (!watchList.some(item => item.mfProductId === mfProductId)) {
    watchList.push({ mfProductId, mfProductName });
    localStorage.setItem('watchList', JSON.stringify(watchList));
  }
};

export const removeFromWatchList = (mfProductId) => {
  const watchList = getWatchList();
  const newWatchList = watchList.filter(item => item.mfProductId !== mfProductId);
  localStorage.setItem('watchList', JSON.stringify(newWatchList));
};
