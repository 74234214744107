import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
    Container, Grid, Paper, CircularProgress, TextField, Box, Button, Divider, Stack,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Typography
} from '@mui/material';
import LoginWithGoogle from '../components/SSO/LoginWithGoogle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../Context/UserContext';
import './auth.style.css'
import { baseUrl } from '../utils/baseUrl';

const StyledSearchbar = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: "space-between",
    height: 45,
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    padding: "30px",
    backgroundColor: "#260078",
    color: "#fff"
});

const initialFormState = {
    email: "",
    password: ""
}

const StyledLink = styled(Link)({
    color: '#fff',
    textDecoration: 'none',
    textWrap: 'nowrap',
    '&:hover': {
        textDecoration: 'underline',
    }
});

const Login = () => {
    const navigate = useNavigate();
    const { setUserInfo } = useUser();
    const inputRefs = useRef([]);
    const [value, setValue] = useState('');
    const valueLength = 4;
    const [openOTPVerificationModel, setOpenOTPVarificationModel] = useState(false)
    const [email, setEmail] = useState("")

    const [formData, setFormData] = useState(initialFormState);
    const [loading, setLoading] = useState(false)

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    }



    const handleOTPChange = (e, index) => {
        const newValue = e.target.value;
        if (/^\d*$/.test(newValue)) {
            const newOtpValue = value.split('');
            newOtpValue[index] = newValue;
            const updatedValue = newOtpValue.join('');
            setValue(updatedValue);
            if (newValue && index < valueLength - 1) {
                inputRefs.current[index + 1].focus();
            }
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && !value[index] && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };

    const handleOTPSubmit = async (e) => {
        e.preventDefault();
        const payload = {
            email: email,
            otp: value,
            actionType: "Login"
        }
        try {
            setLoading(true);
            const response = await fetch(`${baseUrl}/user/verifyLoginOTP`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload)
            });

            const data = await response.json();
            setLoading(false);
            console.log("Otp verification:", data);
            if(data.status == false){
                toast.error(data.message);
                //setOpenOTPVarificationModel(false)
                return;
            }
            // if (data.message !== "OTP verified successfully.") {
            //     throw new Error("Something went wrong")
            // }
            setOpenOTPVarificationModel(false)
            const userProfileData = {
                "email": email,
                "expiryTime": "",
                "name": "",
                "pictureLink": ""
            }

            const userProfileResponse = await fetch(`${baseUrl}/user/userProfile`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userProfileData)
            });
            const profileResponseData = await userProfileResponse.json();
            console.log("User Profile Data ", profileResponseData);
            if (profileResponseData.status == true) {
                const userData = {
                    clientCode: profileResponseData.uniqueClientCode,
                    email: email,
                    verificationActionType: "Login",
                    loginTimestamp: Date.now(),
                    customerCounts: profileResponseData.customerCounts || 0,
                    customerInvestment: profileResponseData.customerInvestment || 0,
                    selfLumpSumInvestment: profileResponseData.selfLumpSumInvestment || 0,
                    selfLumpSumCurrentValue: profileResponseData.selfLumpSumCurrentValue || 0,
                    selfSIPInvestment: profileResponseData.selfSIPInvestment || 0,
                    selfSIPCurrentValue: profileResponseData.selfSIPCurrentValue || 0,
                    selfProfitLossValue: profileResponseData.selfProfitLossValue || 0,
                    selfProfitLossPercentage: profileResponseData.selfProfitLossPercentage || 0,
                    pictureLink: profileResponseData.pictureLink || null,
                    userName: profileResponseData.userName || "User",
                    totalInvestment: profileResponseData.totalInvestment || 0,
                    totalCurrentValue: profileResponseData.totalCurrentValue || 0,
                    isInvestorRegistered: profileResponseData.investorRegistered,
                    isDistributorRegistered: profileResponseData.distributorRegistered
                };
                setUserInfo(userData)
                window.localStorage.setItem("userInfo", JSON.stringify(userData))
                toast.success('Login  successfully');
                // here setTimeout to show toaster only  if you use other place no need to  setTimeout if page nagigation not useing
                //  you can use wrong otp to check toaster also 
                setTimeout(() => {
                    navigate("/mutual-funds/explore")
                }, 2000)
            } else {
                console.log("Exception while getting login profile.");
                toast.error(profileResponseData.message);
            }
            setLoading(false);
        } catch (error) {
            toast.error(error.message || 'Error');
            setLoading(false);
        }
    };



    const handleWatchlistClick = () => {
        navigate('/watchlist');
    };

    const handleOTPModel = () => {
        setOpenOTPVarificationModel(true)
    }
    const handleCloseModel = () => {
        setOpenOTPVarificationModel(false)
    }

    const handleLoginWithEmailAndPassword = async (e) => {
        e.preventDefault();
        const payload = {
            password: formData.password,
            email: formData.email,
        }
        try {
            setLoading(true);
            const response = await fetch(`${baseUrl}/user/loginWithEmailAndPassword`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload)
            });

            const data = await response.json();
            console.log("Login with email and password..", data);
            if (data.status == true) {
                const userProfileData = {
                    "email": formData.email,
                    "expiryTime": "",
                    "name": "",
                    "pictureLink": ""
                }
                const userProfileResponse = await fetch(`${baseUrl}/user/userProfile`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(userProfileData)
                });
                const profileResponseData = await userProfileResponse.json();
                console.log("User Profile Data ", profileResponseData);
                if (profileResponseData.status == true) {
                    const userData = {
                        clientCode: profileResponseData.uniqueClientCode || "F56IN8",
                        email: email,
                        verificationActionType: "Login",
                        loginTimestamp: Date.now(),
                        customerCounts: profileResponseData.customerCounts || 0,
                        customerInvestment: profileResponseData.customerInvestment || 0,
                        selfLumpSumInvestment: profileResponseData.selfLumpSumInvestment || 0,
                        selfLumpSumCurrentValue: profileResponseData.selfLumpSumCurrentValue || 0,
                        selfSIPInvestment: profileResponseData.selfSIPInvestment || 0,
                        selfSIPCurrentValue: profileResponseData.selfSIPCurrentValue || 0,
                        selfProfitLossValue: profileResponseData.selfProfitLossValue || 0,
                        selfProfitLossPercentage: profileResponseData.selfProfitLossPercentage || 0,
                        pictureLink: profileResponseData.pictureLink || null,
                        userName: profileResponseData.userName || "User",
                        totalInvestment: profileResponseData.totalInvestment || 0,
                        totalCurrentValue: profileResponseData.totalCurrentValue || 0,
                        isInvestorRegistered: profileResponseData.isInvestorRegistered
                    };
                    setUserInfo(userData)
                    window.localStorage.setItem("userInfo", JSON.stringify(userData))
                    navigate("/mutual-funds/explore")
                }
                setLoading(false);
            } else {
                setLoading(false);
                throw new Error("Something went wrong")
            }
        } catch (error) {
            setLoading(false);
        }
    }

    return (
        <section>
            {loading && (
                <Dialog open={loading}>
                    <DialogContent>
                        <DialogContentText>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <CircularProgress />
                            </Box>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            )}
            <section>
                <StyledSearchbar>
                    <Box>
                        <Typography sx={{ color: "#AEADD6", }}>
                            <img src="/assets/logo/niveshiq.svg" width={100} />
                        </Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "row", width: "90%", gap: "15px", }}>
                        <Box sx={{ display: "flex", gap: "10px", alignItems: 'center' }}>
                            {/* <StyledLink to="/">Home</StyledLink> */}
                            {/* <StyledLink to="/mutual-funds">Mutual Funds</StyledLink> */}
                            {/* <StyledLink to="/sip-calculator">SIP Calculator</StyledLink> */}
                            <StyledLink to="/about-us">About Us</StyledLink>
                        </Box>
                        {/* <TextField
                            fullWidth
                            variant="standard"
                            placeholder="Search..."
                            sx={{
                                width:"65%",
                                border: "1px solid #ccc",
                                padding: "2px",
                                borderRadius: "20px",
                                '& .MuiInput-root::before': {
                                    borderBottom: 'none !important',
                                },
                                backgroundColor: "#fff",
                                '& .MuiInputBase-root': {
                                    borderBottom: 'none !important',
                                },
                                '& .MuiInputBase-root:before': {
                                    borderBottom: 'none !important',
                                },
                                '& .MuiInputBase-root:after': {
                                    borderBottom: 'none !important',
                                },
                                '& .MuiInputBase-root:hover:before': {
                                    borderBottom: 'none !important',
                                }
                            }}
                            InputProps={{
                                startAdornment: (
                                    <IconButton size="small">
                                        <SearchIcon />
                                    </IconButton>
                                ),
                            }}
                        /> */}
                        {/* <Box sx={{ display: "flex", color: "blue" }}>
                            <IconButton size="small" sx={{ color: "#1976d2" }}
                                onClick={handleWatchlistClick}>
                                <FavoriteBorderIcon />
                                Watchlist
                            </IconButton>
                            <IconButton size="small" sx={{ color: "#1976d2" }}>
                                <NotificationsIcon />
                            </IconButton>
                        </Box> */}
                    </Box>
                </StyledSearchbar>
            </section>
            <Container>
                <ToastContainer />
                <Paper elevation={3} sx={{ padding: "20px", margin: "20px 0", minHeight: "85vh" }}>
                    <Grid container>
                        <Grid item sx={12} md={6}>
                            <div style={{ width: "95%", padding: "10px", height: "100%" }}>
                                <img src="/assets/logo/niveshiq.svg"
                                    style={{ width: "100%", height: "100%", borderRadius: "7px" }} />
                            </div>
                        </Grid>
                        <Grid item sx={12} md={6}>
                            <Stack display="flex" gap="10px" sx={{
                                padding: "10px",
                                borderRadius: "7px",
                            }}>

                                <div>
                                    <LoginWithGoogle handleOTPModel={handleOTPModel} setEmail={setEmail} />
                                </div>
                                <Divider />
                                <Grid container sx={{
                                    gap: "20px",
                                    marginLeft: "110px"
                                }}>
                                    <Grid item xs={7}>
                                        <TextField
                                            fullWidth
                                            label="Email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={7}>
                                        <TextField
                                            fullWidth
                                            label="Password"
                                            name="password"
                                            value={formData.password}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                </Grid>
                                <Box mt={3} display="flex" justifyContent="space-around">
                                    <Button sx={{
                                        backgroundColor: "#260078",
                                        width: "60%",
                                        color: "#fff",
                                        "&:hover": {
                                            backgroundColor: "#260078",
                                        }
                                    }} onClick={handleLoginWithEmailAndPassword}>
                                        login
                                    </Button>
                                </Box>
                                <p className='if-dont-have-acc' style={{ marginLeft: "100px" }}>If you forgot the password <Link to="/reset-passowrd" style={{
                                    marginInlineStart: 6,
                                }}>Click Here </Link></p>
                            </Stack>
                        </Grid>

                    </Grid>
                </Paper>
            </Container >

            <Dialog open={openOTPVerificationModel} onClose={handleCloseModel}>
                <DialogTitle>
                    Enter OTP
                    <p className='otp-to-email'>send to your email {email}</p>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <form onSubmit={handleOTPSubmit}>
                            <div className="otp-input">
                                {Array.from({ length: valueLength }).map((_, index) => (
                                    <input
                                        key={index}
                                        type="text"
                                        maxLength="1"
                                        value={value[index] || ''}
                                        onChange={(e) => handleOTPChange(e, index)}
                                        onKeyDown={(e) => handleKeyDown(e, index)}
                                        ref={(el) => (inputRefs.current[index] = el)}
                                        style={{
                                            width: '50px',
                                            height: '45px',
                                            fontSize: '20px',
                                            borderRadius: '4px',
                                            border: '1px solid #ccc',
                                            textAlign: 'center',
                                        }}
                                    />
                                ))}
                            </div>

                            {loading && (
                                <Dialog open={loading}>
                                    <DialogContent>
                                        <DialogContentText>
                                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                <CircularProgress />
                                            </Box>
                                        </DialogContentText>
                                    </DialogContent>
                                </Dialog>
                            )}
                            <Box mt={3} display="flex" justifyContent="space-around">
                                <Button type="submit" variant="contained" color="primary">
                                    Submit OTP
                                </Button>
                            </Box>
                        </form>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModel} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </section>
    )
}

export default Login
