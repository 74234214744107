import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link, useLocation } from 'react-router-dom';

const StyledTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: '#ffffff',
  color: '#000000',
  boxShadow: 'none',
  display: 'flex',
  flexGrow: 1,
}));


const DistributorTopBar  = React.memo((distributorDetailsStored) => {
  const location = useLocation();
  //const[distributorDetailsStored, setDistributorDetailsStored] = useState();  

  const getTabIndex = React.useCallback((pathname) => {
    switch (pathname) {
      case '/distributor/clients':
        return 0;
      case '/distributor/make/trxn':
        return 1;
      case '/distributor/generate/reports':
        return 2;
      case '/distributor/details':
      case '/distributor/add/details':
        return 3;
    //   case '/mutual-funds/orders':
    //     return 4;
      default:
        return false;
    }
  }, []);

  useEffect(() => {
    const uniqueClientCode = JSON.parse(window.localStorage.getItem("userInfo"))?.clientCode;
    // const demo = false;
    // if(!uniqueClientCode){
    //     setDistributorDetailsStored(false);
    // }
  });

  
  return (
    <StyledTabs value={getTabIndex(location.pathname)} textColor="inherit" indicatorColor="primary" variant="scrollable">
      <Tab
        label="Client Details"
        component={Link}
        to="/distributor/clients"
        sx={{ textDecoration: 'none', color: 'inherit' }}
      />
      <Tab
        label="Make Transaction"
        component={Link}
        to="/distributor/make/trxn"
        sx={{ textDecoration: 'none', color: 'inherit' }}
      />
      <Tab
        label="Reports"
        component={Link}
        to="/distributor/generate/reports"
        sx={{ textDecoration: 'none', color: 'inherit' }}
      />
      if(distributorDetailsStored){

      } else{

      }
      
      {
        !distributorDetailsStored && <Tab
        label="Distributor Details"
        component={Link}
        to= {"/distributor/add/details"} 
        sx={{ textDecoration: 'none', color: 'inherit' }}
      />
      }
      
      {
        distributorDetailsStored && <Tab
        label="Distributor Details"
        component={Link}
        to= {"/distributor/details"} 
        sx={{ textDecoration: 'none', color: 'inherit' }}
      />
      }
      
      
      {/* <Tab
        label="Distributor Details"
        component={Link}
        to= {distributorDetailsStored == true ? "/distributor/details":"/distributor/add/details"} 
        sx={{ textDecoration: 'none', color: 'inherit' }}
      /> */}
      
      {/* <Tab
        label="Orders"
        component={Link}
        to="/mutual-funds/orders"
        sx={{ textDecoration: 'none', color: 'inherit' }}
      /> */}
    </StyledTabs>
  );
});

export default DistributorTopBar;
