import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Searchbar from './SearchBar';
import { SidebarProvider } from '../Context/SidebarContext';
import { Outlet } from 'react-router-dom';
import DistributorSideBar from './DistributorSideBar';
import DistributorTopBar from './DistributorTopBar';
const LayoutContainer = styled('div')`
  display: flex;
`;
//const[uniqueClientCode, setUniqueClientCode] = useState();

const Content = styled('main')(({ theme }) => ({
  flexGrow: 1,
  padding: 24,
  // marginTop: 64,
  // marginLeft: 250,
  [theme.breakpoints.down('sm')]: {
    marginLeft: 0,
  },
}));

const DistributorLayOut = ({ children }) => {
  const[distributorDetailsStored,setDistributorDetailsStored ] = useState();
  useEffect(() => {
    const uniqueClientCode = JSON.parse(window.localStorage.getItem("userInfo"))?.clientCode;
    const demo = false;
    if(uniqueClientCode){
        setDistributorDetailsStored(false);
    }
  });
  
  return (
    <SidebarProvider>
      <Searchbar />
      <LayoutContainer>
        <DistributorSideBar />
        <Content>
          <DistributorTopBar distributorDetailsStored={distributorDetailsStored}/>
          <Outlet />
        </Content>
      </LayoutContainer>
    </SidebarProvider>
  );
};

export default DistributorLayOut;
