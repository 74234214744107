const data = {
    overview: {
        invested: "₹1.59L",
        current: "₹1.91L",
        pl: "31.87k",
        plPercent: "+19.92%",
        xirr: "View portfolio XIRR"
    },
    funds: [
        {
            name: "HDFC Mid-Cap Opportunities Fund",
            category: "Growth · Equity · Mid Cap",
            invested: 39997.94,
            current: 40732.63,
            pl: 734.68,
            plPercent: "+1.84%",
            logo:"/assets/logo/Mutual_Fund_Info.png"
        },
        {
            name: "HDFC Hybrid Equity Fund",
            category: "Growth · Hybrid · Aggressive",
            invested: 49997.38,
            current: 59991.18,
            pl: 9993.79,
            plPercent: "+19.99%",
            logo:"/assets/logo/mutual_fund.png"
        },
        {
            name: "ICICI Prudential Equity & Debt Fund",
            category: "Growth · Hybrid · Aggressive",
            invested: 69996.36,
            current: 91144.99,
            pl: 21148.63,
            plPercent: "+30.21%",
            logo:"/assets/logo/Mutual_Fund_Info.png"
        },
    ],
};

module.exports={
    data
}