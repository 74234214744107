import React, { useEffect, useState } from 'react';
import { baseUrl } from '../utils/baseUrl';
import { Paper, Box, Typography } from '@mui/material';
import { useSidebar } from '../Context/SidebarContext';
import './pages.style.css'
import MyHoldings from '../components/holdings/MyHoldings';
import { useNavigate } from 'react-router-dom';

const Holding = () => {
  const navigate = useNavigate()
  const { selectedFilters ,removeFilter} = useSidebar();
  const [mutualFund, setMutualFund] = useState([]);

  const handleAddToWatchList = async (mfProductId, mfProductName) => {
    console.log("Inside handle add to watchlist....", mfProductId);
    const uniqueClientCode=JSON.parse(window.localStorage.getItem("userInfo")).clientCode
    if (!uniqueClientCode){
        navigate("/login")
    }
    const watchListData = {
        uniqueClientCode: uniqueClientCode,
        mfProductID: mfProductId,
        status: true,
    };
    const response= await fetch(`${baseUrl}/user/add-to-watchlist`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(watchListData)
    });
    const data = await response.json();
    console.log("Printing add to watchlist response....", data);

    //console.log("Ptinting client code" + userData.clientCode);
    // if (watchList.some(item => item.mfProductId === mfProductId)) {
    //     removeFromWatchList(mfProductId);
    // } else {
    //     addToWatchList(mfProductId, mfProductName);
    // }
    // setWatchList(getWatchList());
};


  useEffect(() => {
      const getMutualFunds = async () => {
          try {
              const body = {
                  amcs: ["360 ONE Mutual Fund (Formerly known as IIFL Mutual"],
                  productCategories: ["hybrid"],
                  productSubCategories: ["Balanced Hybrid"],
                  dividendType: [],
                  dividendPlans: []
              };

              const response = await fetch(`${baseUrl}/products/mutual-funds`, {
                  method: "POST",
                  headers: {
                      'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(body)
              });

              if (!response.ok) {
                  throw new Error("An error occurred");
              }

              const data = await response.json();
              setMutualFund(data.mfProductResponseElementDTOList);
          } catch (error) {
              console.error("Error fetching mutual funds:", error);
          }
      };

      getMutualFunds();
  }, [selectedFilters]);
  console.log("mutualFund", mutualFund)
  return (
    <div className='manage-content-area'>
      <Box>
        {Object.keys(selectedFilters).map((filterKey) => (
          selectedFilters[filterKey].length > 0 && (
            <Box key={filterKey} display={"flex"} alignItems="center" padding={"20px"}>
              {selectedFilters[filterKey].map((filterValue, index) => (
              filterValue &&  <Typography key={index} variant="body1" component="span" style={{
                  marginRight: '8px',
                  padding: "2px 10px",
                  backgroundColor: "#1976d2",
                  borderRadius: "30px",
                  color: "#fff",
                  cursor:"pointer"
                }}>
                  {filterValue} <span style={{ marginLeft: "2px" }}
                  onClick={() => removeFilter(filterKey, filterValue)}>X</span>
                </Typography>
              ))}
            </Box>
          )
        ))}
      </Box>
      <Box p={2} sx={{
        display: "flex",
        flexDirection: "column",
        gap: "20px"
      }}>
        <MyHoldings/>
      </Box>
    </div>
  );
};

export default Holding;
