import React, {useState} from 'react';
import { Container, Grid, Paper, Typography, Button, Box } from '@mui/material';
import {  Card, CardContent, Avatar, Divider, } from '@mui/material';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import './multistep.style.css'
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../Context/UserContext';
import { baseUrl } from '../../utils/baseUrl';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import HomeIcon from '@mui/icons-material/Home';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ClassIcon from '@mui/icons-material/Class';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import BusinessIcon from '@mui/icons-material/Business';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import PersonIcon from '@mui/icons-material/Person';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import SecurityIcon from '@mui/icons-material/Security';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import StorageIcon from '@mui/icons-material/Storage';
import GavelIcon from '@mui/icons-material/Gavel';

const RegistrationDetails = ({ mfInvestorInfo }) => {
  console.log("mfInvestorInfo", mfInvestorInfo)
  const { setUserInfo, userInfo } = useUser();
  const [loading, setLoading] = useState(false)
  const navigate=useNavigate()
  const {
    uniqueClientCode,
    mfAccountHoldingInfoDTO,
    mfAccountHolderBankInfoDTO, 
    fatcaUploadDetails, 
    residentAddress, 
    nominee1, 
    nominee2, 
    nominee3
  } = mfInvestorInfo;

//   const generatePDF = () => {
//     const doc = new jsPDF();
//     doc.setFontSize(18);
//     doc.text('Registration Details', 14, 22);

//     const columns = [
//       { header: 'Field', dataKey: 'field' },
//       { header: 'Value', dataKey: 'value' }
//     ];

//     const rows = [
//       { field: 'Client Code', value: uniqueClientCode },
//       { field: 'Primary Holder Name', value: `${mfAccountHoldingInfoDTO.firstName} ${mfAccountHoldingInfoDTO.middleName} ${mfAccountHoldingInfoDTO.lastName}` },
//       { field: 'Date of Birth', value: mfAccountHoldingInfoDTO?.dob?.$d?.toLocaleDateString() },
//       { field: 'PAN', value: mfAccountHoldingInfoDTO.pan },
//       { field: 'Mobile Number', value: mfAccountHoldingInfoDTO.mobileNumber },
//       { field: 'Email', value: mfAccountHoldingInfoDTO.email },
//       { field: 'Account Type', value: mfAccountHoldingInfoDTO.accountType },
//       { field: 'Account Number', value: mfAccountHoldingInfoDTO.accountNumber },
//       { field: 'IFSC Code', value: mfAccountHoldingInfoDTO.ifscCode },
//       { field: 'Bank Name', value: mfAccountHoldingInfoDTO.bankName },
//       { field: 'Branch Name', value: mfAccountHoldingInfoDTO.branchName },
//       { field: 'Nominee 1', value: `${nominee1.name} (${nominee1.relationShip}, ${nominee1.percentage}%)` },
//       { field: 'Nominee 2', value: `${nominee2.name} (${nominee2.relationShip}, ${nominee2.percentage}%)` },
//       { field: 'Nominee 3', value: `${nominee3.name} (${nominee3.relationShip}, ${nominee3.percentage}%)` },
//       { field: 'Resident Address', value: `${residentAddress.address1}, ${residentAddress.address2}, ${residentAddress.city}, ${residentAddress.state}, ${residentAddress.pincode}` },
//       { field: 'Tax Status', value: fatcaUploadDetails.taxStatus },
//       { field: 'Gender', value: fatcaUploadDetails.gender },
//       { field: 'Occupation', value: fatcaUploadDetails.occupation },
//       { field: 'Holding Nature', value: fatcaUploadDetails.holdingNature },
//       { field: 'FATCA Details', value: `Address Type: ${fatcaUploadDetails.addrType}, Politically Exposed: ${fatcaUploadDetails.politicallyExposedPerson}, Income Slab: ${fatcaUploadDetails.incomeSlab}, Source of Wealth: ${fatcaUploadDetails.sourceWealth}` }
//     ];

//     autoTable(doc, { columns, body: rows, startY: 30 });
//     doc.save('Registration_Details.pdf');
//   };

  const handleClientRegister = async(e) => {
    e.preventDefault();
    const uniqueClientCode=JSON.parse(window.localStorage.getItem("userInfo"))?.clientCode || "";
    const payload = {
      uniqueClientCode:uniqueClientCode
    }
    try {
        setLoading(true);
        const response = await fetch(`${baseUrl}/user/submitInvestorDetailsToPlatform`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload)
        });
        const data = await response.json();
        console.log("Submit client details to platform.", data);
        if(data.status == true){
            const userData = window.localStorage.setItem("userInfo");
            userData.isInvestorRegistered = true;
            setUserInfo(userData)
            window.localStorage.setItem("userInfo", JSON.stringify(userData))
            navigate("/mutual-funds/explore")
            setLoading(false);
        } else {
            setLoading(false);
            throw new Error("Something went wrong")
        }    
    } catch (error) {
        setLoading(false);
    }
  }

  const handleClientEdit = async(e) => {
    e.preventDefault();
    const uniqueClientCode=JSON.parse(window.localStorage.getItem("userInfo"))?.clientCode || "";
    const payload = {
      uniqueClientCode:uniqueClientCode
    }
    try {
        setLoading(true);
        const response = await fetch(`${baseUrl}/user/submitInvestorDetailsToPlatform`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload)
        });
        const data = await response.json();
        console.log("Submit client details to platform.", data);
        if(data.status == true){
            setUserInfo(
                {
                    ...userInfo,
                    isInvestorRegistered:true
                }
            )
            const userData = userInfo;
            window.localStorage.setItem("userInfo", JSON.stringify(userData));
            navigate("/mutual-funds/explore")
            setLoading(false);
        } else {
            setLoading(false);
            throw new Error("Something went wrong")
        }    
    } catch (error) {
        setLoading(false);
    }
  }

  // const { mfAccountHoldingInfoDTO, mfAccountHolderBankInfoDTO, fatcaUploadDetails, residentAddress, nominee1, nominee2, nominee3 } = profileData;
  const initials = `${mfInvestorInfo.mfAccountHoldingInfoDTO.firstName}${mfInvestorInfo.mfAccountHoldingInfoDTO.middleName}${mfInvestorInfo.mfAccountHoldingInfoDTO.lastName}`;
  return (
    <Box sx={{ p: 4, minHeight: '100vh', display: "flex", flexDirection: "column", gap: "30px" }}>
            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} gap={3} justifyContent="center">
                <Card sx={{ borderRadius: 2, flex: 1 }}>
                    <CardContent>
                        {/* <Avatar
                            sx={{
                                bgcolor: 'black',
                                color: 'white',
                                width: 66,
                                height: 66,
                                fontSize: '24px',
                            }}
                        >
                            {initials}
                        </Avatar> */}
                        <Typography variant="h5" gutterBottom>
                            {mfInvestorInfo.mfAccountHoldingInfoDTO.firstName} {mfInvestorInfo.mfAccountHoldingInfoDTO.middleName} {mfInvestorInfo.mfAccountHoldingInfoDTO.lastName}
                        </Typography>
                        <Typography variant="body1" color="text.secondary">
                            DOB: {mfInvestorInfo.mfAccountHoldingInfoDTO.dob}
                        </Typography>
                        <Typography variant="body1" color="text.secondary">
                            Mobile: {mfInvestorInfo.mfAccountHoldingInfoDTO.mobileNumber}
                        </Typography>

                        <Box display="flex" alignItems="center" mb={3}>
                            <ContactMailIcon sx={{ mr: 1, color: 'primary.main' }} />
                            <Typography variant="body2">
                                <strong>Email:</strong> {mfInvestorInfo.mfAccountHoldingInfoDTO.email}
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" mb={3}>
                            <CreditCardIcon sx={{ mr: 1, color: 'primary.main' }} />
                            <Typography variant="body2">
                                <strong>PAN:</strong> {mfInvestorInfo.mfAccountHoldingInfoDTO.pan}
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" mt={2}>
                            <HomeIcon sx={{ mr: 1, color: 'primary.main' }} />
                            <Typography variant="body2">
                                Address: {mfInvestorInfo.residentAddress.address1}, {mfInvestorInfo.residentAddress.city}, {mfInvestorInfo.residentAddress.state} - {mfInvestorInfo.residentAddress.pincode}, {mfInvestorInfo.residentAddress.country}
                            </Typography>
                        </Box>
                    </CardContent>
                </Card>

                <Card sx={{ borderRadius: 2, flex: 2 }}>
                    <CardContent>
                        <Card sx={{ borderRadius: 2, flex: 2 }}>
                            <CardContent>

                                {/* Bank Details Section */}
                                <Box display="grid" gridTemplateColumns={{ xs: '1fr', sm: 'repeat(2, 1fr)' }} gap={3}>
                                    <Box display="flex" alignItems="center">
                                        <AccountBalanceIcon sx={{ mr: 1, color: 'primary.main' }} />
                                        <Typography variant="body2">
                                            <strong>Bank:</strong> {mfInvestorInfo.mfAccountHolderBankInfoDTO.bankName}
                                        </Typography>
                                    </Box>
                                    <Box display="flex" alignItems="center">
                                        <CreditCardIcon sx={{ mr: 1, color: 'primary.main' }} />
                                        <Typography variant="body2">
                                            <strong>Account No:</strong> {mfInvestorInfo.mfAccountHolderBankInfoDTO.accountNumber}
                                        </Typography>
                                    </Box>
                                    <Box display="flex" alignItems="center">
                                        <ClassIcon sx={{ mr: 1, color: 'primary.main' }} />
                                        <Typography variant="body2">
                                            <strong>Account Type:</strong> {mfInvestorInfo.mfAccountHolderBankInfoDTO.accountType}
                                        </Typography>
                                    </Box>
                                    <Box display="flex" alignItems="center">
                                        <AccountBalanceWalletIcon sx={{ mr: 1, color: 'primary.main' }} />
                                        <Typography variant="body2">
                                            <strong>IFSC Code:</strong> {mfInvestorInfo.mfAccountHolderBankInfoDTO.ifscCode}
                                        </Typography>
                                    </Box>
                                    <Box display="flex" alignItems="center">
                                        <AccountBalanceWalletIcon sx={{ mr: 1, color: 'primary.main' }} />
                                        <Typography variant="body2">
                                            <strong>MICR Number:</strong> {mfInvestorInfo.mfAccountHolderBankInfoDTO.micrNumber}
                                        </Typography>
                                    </Box>
                                    <Box display="flex" alignItems="center">
                                        <BusinessIcon sx={{ mr: 1, color: 'primary.main' }} />
                                        <Typography variant="body2">
                                            <strong>Branch:</strong> {mfInvestorInfo.mfAccountHolderBankInfoDTO.branchName}
                                        </Typography>
                                    </Box>
                                </Box>

                                {/* Branch Address Section */}
                                <Box mt={3}>
                                    <Typography variant="h6" gutterBottom>
                                        Branch Address
                                    </Typography>
                                    <Box display="flex" alignItems="center" mb={2}>
                                        <HomeIcon sx={{ mr: 1, color: 'primary.main' }} />
                                        <Typography variant="body2">
                                            {mfInvestorInfo.mfAccountHolderBankInfoDTO.branchAddress1 && <span>{mfInvestorInfo.mfAccountHolderBankInfoDTO.branchAddress1}</span>}
                                            {mfInvestorInfo.mfAccountHolderBankInfoDTO.branchAddress2 && <span>, {mfInvestorInfo.mfAccountHolderBankInfoDTO.branchAddress2}</span>}
                                            {mfInvestorInfo.mfAccountHolderBankInfoDTO.branchAddress3 && <span>, {mfInvestorInfo.mfAccountHolderBankInfoDTO.branchAddress3}</span>}
                                            {mfInvestorInfo.mfAccountHolderBankInfoDTO.branchCity && <span>, {mfInvestorInfo.mfAccountHolderBankInfoDTO.branchCity}</span>}
                                            {mfInvestorInfo.mfAccountHolderBankInfoDTO.branchPincode && <span>, {mfInvestorInfo.mfAccountHolderBankInfoDTO.branchPincode}</span>}
                                            {mfInvestorInfo.mfAccountHolderBankInfoDTO.branchCountry && <span>, {mfInvestorInfo.mfAccountHolderBankInfoDTO.branchCountry}</span>}
                                        </Typography>
                                    </Box>
                                </Box>
                            </CardContent>
                        </Card>


                    </CardContent>
                </Card>
            </Box>

            <Divider sx={{ my: 2 }} />

            {/* FATCA Uploaded Details */}
            <Card sx={{ borderRadius: 2 }}>
                <CardContent>

                    <Typography variant="h6" gutterBottom>
                        Nominees
                    </Typography>
                    {mfInvestorInfo.nominee1 && [mfInvestorInfo.nominee1, mfInvestorInfo.nominee2, mfInvestorInfo.nominee3].map((nominee, index) => (
                        <Box key={index} mt={1} display="flex" alignItems="center">
                            <FamilyRestroomIcon sx={{ mr: 1, color: 'primary.main' }} />
                            <Typography variant="body2">
                                Name: {nominee?.name} | Relationship: {nominee?.relationShip} | Percentage: {nominee?.percentage}%
                            </Typography>
                        </Box>
                    ))}
                    <Divider sx={{ my: 2 }} />

                    <Typography sx={{ my: 2 }} >FATCA Uploaded Details</Typography>
                    <Divider sx={{ my: 2 }} />

                    <Box display="grid" gridTemplateColumns={{ xs: '1fr', sm: 'repeat(2, 1fr)' }} gap={3}>
                        {/* <Box display="flex" alignItems="center">
                            <PersonIcon sx={{ mr: 1, color: 'primary.main' }} />
                            <Typography variant="body2">
                                <strong>Investor Name:</strong> {mfInvestorInfo.fatcaUploadDetails.investorName}
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <CreditCardIcon sx={{ mr: 1, color: 'primary.main' }} />
                            <Typography variant="body2">
                                <strong>PAN Number:</strong> {mfInvestorInfo.fatcaUploadDetails.panNumber}
                            </Typography>
                        </Box> */}
                        <Box display="flex" alignItems="center">
                            <MonetizationOnIcon sx={{ mr: 1, color: 'primary.main' }} />
                            <Typography variant="body2">
                                <strong>Income Slab:</strong> {mfInvestorInfo.fatcaUploadDetails.incomeSlab}
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <SecurityIcon sx={{ mr: 1, color: 'primary.main' }} />
                            <Typography variant="body2">
                                <strong>Politically Exposed Person:</strong> {mfInvestorInfo.fatcaUploadDetails.politicallyExposedPerson}
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <AttachMoneyIcon sx={{ mr: 1, color: 'primary.main' }} />
                            <Typography variant="body2">
                                <strong>Source of Wealth:</strong> {mfInvestorInfo.fatcaUploadDetails.sourceWealth}
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <HomeIcon sx={{ mr: 1, color: 'primary.main' }} />
                            <Typography variant="body2">
                                <strong>Address Type:</strong> {mfInvestorInfo.residentAddress.addrType}
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <StorageIcon sx={{ mr: 1, color: 'primary.main' }} />
                            <Typography variant="body2">
                                <strong>Data Source:</strong> {mfInvestorInfo.fatcaUploadDetails.dataSource}
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <GavelIcon sx={{ mr: 1, color: 'primary.main' }} />
                            <Typography variant="body2">
                                <strong>Tax Status:</strong> {mfInvestorInfo.fatcaUploadDetails.taxStatus}
                            </Typography>
                        </Box>
                        {/* <Box display="flex" alignItems="center">
                            <BusinessIcon sx={{ mr: 1, color: 'primary.main' }} />
                            <Typography variant="body2">
                                <strong>SP Name:</strong> {mfInvestorInfo.fatcaUploadDetails.spName}
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <PersonIcon sx={{ mr: 1, color: 'primary.main' }} />
                            <Typography variant="body2">
                                <strong>FR Name:</strong> {mfInvestorInfo.fatcaUploadDetails.frName}
                            </Typography>
                        </Box> */}
                    </Box>
                    

                </CardContent>
            </Card>

            <Divider sx={{ my: 2 }} />
            <Card sx={{ borderRadius: 2 }}>
              <CardContent>
                <Box mt={3} display="flex" justifyContent="space-around">
                  {/* <Button variant="contained" color="primary" onClick={generatePDF}>
                    Download Registration Form
                  </Button> */}
                  <Button variant="contained" color="primary" onClick={handleClientEdit}>
                    Edit Profile
                  </Button>
                  <Button variant="contained" color="primary" onClick={handleClientRegister}>
                    Register
                  </Button>
                </Box>
              </CardContent>
            </Card>

        </Box>
    
    // <Container>
    //   <Paper elevation={3} sx={{ padding: "20px", margin: "20px 0" }}>
    //     <Grid container>
    //       <Grid item sx={12} md={6}>
    //         <Typography variant="h5" gutterBottom>
    //           Registration Overview
    //         </Typography>
    //         <Box>
    //           {/* <div className='profile'>
    //             <img src="/assets/logo/profile.png" alt="registration-overview" />
    //           </div> */}
    //         </Box>
    //         <Box mt={2}>
    //           <Typography variant="h6" className='user-info-header'>Other Information</Typography>
    //           <p className='saved-info-filed'>
    //             <Typography><strong>Tax Status</strong> </Typography>
    //             <Typography className='saved-info'>
    //               {taxStatus}
    //             </Typography>
    //           </p>
    //           <p className='saved-info-filed'>
    //             <Typography><strong>Gender</strong> </Typography>

    //             <Typography className='saved-info'>{gender}</Typography>
    //           </p>
    //           <p className='saved-info-filed'>
    //             <Typography><strong>Occupation</strong> </Typography>

    //             <Typography className='saved-info'>{occupation}</Typography>
    //           </p>
    //           <p className='saved-info-filed'>
    //             <Typography><strong>Holding Nature</strong> </Typography>
    //             <Typography className='saved-info'>{holdingNature}</Typography>
    //           </p>
    //         </Box>
    //         <Box mt={2}>
    //           <Typography variant="h6" className='user-info-header'>FATCA Details</Typography>
    //           <p className='saved-info-filed'>
    //             <Typography><strong>Address Type</strong> </Typography>
    //             <Typography className='saved-info'>{fatcaDetails.addrType}</Typography>
    //           </p>
    //           <p className='saved-info-filed'>
    //             <Typography><strong>Politically Exposed Person</strong></Typography>
    //             <Typography className='saved-info'> {fatcaDetails.politicallyExposedPerson}</Typography>
    //           </p>
    //           <p className='saved-info-filed'>
    //             <Typography><strong>Income Slab</strong></Typography>
    //             <Typography className='saved-info'> {fatcaDetails.incomeSlab}</Typography>
    //           </p>
    //           <p className='saved-info-filed'>
    //             <Typography><strong>Source of Wealth</strong> </Typography>

    //             <Typography className='saved-info'>
    //               {fatcaDetails.sourceWealth}
    //             </Typography>
    //           </p>
    //         </Box>
    //       </Grid>
    //       <Grid item sx={12} md={6}>
    //         <Box>
    //           <Typography variant="h6" className='user-info-header'>Primary Holder Information</Typography>
    //           <p className='saved-info-filed'>
    //             <Typography><strong>Name</strong> </Typography>
    //             <Typography className='saved-info'>{primaryHolder.firstName} {primaryHolder.middleName} {primaryHolder.lastName}</Typography>
    //           </p>
    //           <p className='saved-info-filed'>
    //             <Typography><strong>Date of Birth:</strong> </Typography>
    //             <Typography className='saved-info'> {primaryHolder?.dob?.$d?.toLocaleDateString()} </Typography>
    //           </p>
    //           <p className='saved-info-filed'>
    //             <Typography><strong>PAN</strong></Typography>
    //             <Typography className='saved-info'> {primaryHolder.pan}</Typography>
    //           </p>
    //           <p className='saved-info-filed'>
    //             <Typography><strong>Mobile Number</strong></Typography>
    //             <Typography className='saved-info' >{primaryHolder.mobileNumber}</Typography>
    //           </p>
    //           <p className='saved-info-filed'>
    //             <Typography><strong>Mobile Number</strong></Typography>
    //             <Typography className='saved-info'> {primaryHolder.mobileNumber}</Typography>
    //           </p>

    //           <p className='saved-info-filed'>
    //             <Typography><strong>Email</strong> </Typography>
    //             <Typography className='saved-info'>{primaryHolder.email}</Typography>
    //           </p>
    //         </Box>
    //         <Box mt={2}>
    //           <Typography variant="h6" className='user-info-header'>Bank Details</Typography>
    //           <p className='saved-info-filed'>
    //             <Typography><strong>Account Type</strong></Typography>
    //             <Typography className='saved-info'> {accountInfo1.accountType}</Typography>
    //           </p >
    //           <p className='saved-info-filed'>
    //             <Typography><strong>Account Number</strong></Typography>
    //             <Typography className='saved-info'> {accountInfo1.accountNumber}</Typography>
    //           </p>
    //           <p className='saved-info-filed'>
    //             <Typography><strong>IFSC Code</strong> </Typography>
    //             <Typography className='saved-info'>{accountInfo1.ifscCode}</Typography>
    //           </p>
    //           <p className='saved-info-filed'>
    //             <Typography><strong>Bank Name</strong> </Typography>
    //             <Typography className='saved-info'>{accountInfo1.bankName}</Typography>
    //           </p>
    //           <p className='saved-info-filed'>
    //             <Typography><strong>Branch Name</strong></Typography>
    //             <Typography className='saved-info'> {accountInfo1.branchName}</Typography>
    //           </p>
    //         </Box>
    //         <Box mt={2}>
    //           <Typography variant="h6" className='user-info-header'>Nominee Information</Typography>
    //           <p className='saved-info-filed'>
    //             <Typography><strong>Nominee 1</strong> </Typography>
    //             <Typography className='saved-info'>
    //               {nominee1.name} ({nominee1.relationShip}, {nominee1.percentage}%)
    //             </Typography>
    //           </p>
    //           <p className='saved-info-filed'>
    //             <Typography><strong>Nominee 2</strong> </Typography>
    //             <Typography className='saved-info'>{nominee2.name} ({nominee2.relationShip}, {nominee2.percentage}%)</Typography>
    //           </p>
    //           <p className='saved-info-filed'>
    //             <Typography><strong>Nominee 3</strong> </Typography>
    //             <Typography className='saved-info'>{nominee3.name} ({nominee3.relationShip}, {nominee3.percentage}%)</Typography>
    //           </p>
    //         </Box>
    //         <Box mt={2}>
    //           <Typography variant="h6" className='user-info-header'>Resident Address</Typography>
    //           <Typography>{residentAddress.address1}, {residentAddress.address2}, {residentAddress.city}, {residentAddress.state}, {residentAddress.pincode}</Typography>
    //         </Box>
    //       </Grid>
    //     </Grid>



    //     <Box mt={3} display="flex" justifyContent="space-around">
    //       <Button variant="contained" color="primary" onClick={generatePDF}>
    //         Download Registration Form
    //       </Button>
    //       <Button variant="contained" color="primary" onClick={handleClientRegister}>
    //         Register
    //       </Button>
    //     </Box>
    //   </Paper>
    // </Container>
  );
};

export default RegistrationDetails;
